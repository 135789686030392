import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { StyledDrawer } from "../../../shared/components/layout/styles";
import { SearchFilterWrapper, StyledIconButton, StyledInput, StyledPaper } from "../../../shared/components/search/search.styles";
import { InputWithIcon } from "../../../shared/components/search";
import { PslTable } from "../../components/dataTable/pslTable";
import { PslActiveFiltersList } from "../pslFilters/filtersRefactored.page";
import { FilterButton } from "../../../shared/components/button";
import {
  ISupplier,
  MyTasksAtMarketTabs,
  PSL,
  PslComplianceStatus,
  SupplierFilterTabs,
  SupplierTabs
} from "../../domains/psl";
import { PslFilterContainer } from "../pslFilters/filtersRefactored.container";

import { IUserRole, User } from "../../../shared/domains/user/user";
import { RouteComponentProps } from "react-router";
import Helmet from "react-helmet";
import { OrderDirection } from "../../../../lib/odata";
import { useTranslation } from "react-i18next";
import * as R from "ramda";
import { Dictionary } from "ramda";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import { GetMaterialTableColumns, PslMaterialTable } from "../../components/dataTable/pslMaterialTable";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import { FilterCategoryView } from "../../../shared/domains/query/filter";
import GpsIcon from "@material-ui/icons/GpsFixedRounded";
import { Query } from "../../../shared/queryable/query";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ConfirmDialog } from "../../../shared/components/popup";
import TextareaAutosize from "@material-ui/core/TextareaAutosize/TextareaAutosize";
import { ColumnsConfigComponent } from "../../components/columnsConfig/columnsConfig.component";
import { PslTabsComponent } from '../../components/pslTabs/pslTabs.component';

import { GetDefaultPslColumns, GetPslColumns, PslVendorColumnsList } from "../../../shared/domains/user/pslColumns";
import {
  GetDefaultMaterialsColumns,
  GetMaterialColumns,
  MaterialColumnsList
} from "../../../shared/domains/user/materialColumns";
import { GetDefaultMyTasksColumns, GetMyTasksColumns } from "../../../shared/domains/user/myTasksColumns";
import Grid from "@material-ui/core/Grid";
import { CustomFastDate, CustomFastSelect } from "../../../shared/components/form";
import { DateTime, Info } from "luxon";
import { useFetchLanguages } from "../../../shared/domains/master/master.hooks";
import { Tooltip } from "@material-ui/core";
import { IMaterial } from "../../domains/pslMaterial";
import { FeaturesContext, useFeatureFlag } from 'src/app/shared/domains/core/pataflag.context';
import { Features } from 'src/app/shared/domains/core/pataflag';
import {
  materialSearchNotAllOptions,
  materialSearchOptions,
  newMaterialSearchNotAllOptions,
  newMaterialSearchOptions,
  pslSearchNotAllOptions,
  pslSearchOptions
} from '../../domains/pslQuery';
import SearchIcon from '@material-ui/icons/Search';
import { useStylesForToolTip } from 'src/app/shared/components/tooltip/toolTip.styles';
import { useSaveMaterialsColumns, useSavePslMyTasksColumns, useSaveVendorsColumns } from "../../../shared/domains/user/columns.hook";
import GenericColumnsConfigComponent from 'src/app/shared/components/columns/genericColumnsConfig.component';
import GenericSearchCategoryComponent from 'src/app/shared/components/search-component/genericSearch.component';
import { GenericIconButtonsComponent } from 'src/app/shared/components/icon-buttons/genericIconButtons.component';
import { SavePslMaterialsColumnsSuccess, SavePslMyTasksColumnsSuccess, SavePslVendorsColumnsSuccess } from 'src/app/shared/actions/user.actions';
import Notification, { NotificationType } from "../../../shared/components/notification";
import { RootStateStore } from "../../../application.reducers";
import { Form, Switch } from 'antd';
import Mark from "mark.js";
import features = Info.features;
import SpenderViewBtns from '../../components/SpenderViewBtns/spenderViewBtns';

export interface PreferredSuppliersPageProps {
  loading: boolean;
  exporting: boolean;
  list: ISupplier[] | IMaterial[];
  listTabs: [SupplierTabs, SupplierFilterTabs];
  totalCount: number;
  currentPage: number;
  pageSize: number;
  query: Query;
  approverQuery: Query,
  filters: Dictionary<FilterCategoryView>;
  materials: IMaterial[];
  user: User;
  pslToEdit: ISupplier;
}

export interface PreferredSuppliersPageDispatcher {
  init: (tab: SupplierTabs, userRole: IUserRole, exactId?: number, newFilters?: any, pslSearch?: any, materialSearch?: any, isFavorite?: boolean) => void;
  exportPslListData: (query: Query) => void;
  exportPslListDataByEmail: (query: Query) => void;
  newTerm: (term: string, tab: SupplierTabs, isApproval?: boolean) => void;
  newPage: (page: number, tab: SupplierTabs, isApproval?: boolean) => void;
  newPageSize: (pageSize: number, tab: SupplierTabs, isApproval?: boolean) => void;
  newOrder: (field: string, direction: OrderDirection, tab: SupplierTabs, isApproval?: boolean) => void;
  approvePSLs: (ids: number[], done: Function) => void;
  rejectPSLs: (ids: number[], reason: string, done: Function) => void;
  deletePSLs: (ids: number[], done: Function) => void;
  deleteMaterialPSLs: (ids: number[], done: Function) => void;
  newReactivatePSL: (reactivatePsl: { pslId?: number, validTo?: string }, done: Function) => void;
  fetchPsl: (id: number, done: Function) => void;
  updateFilters: (updatedFilters: any, tab: SupplierTabs, isApproval?: boolean) => void;
}

interface PreferredSuppliersProps extends PreferredSuppliersPageProps, PreferredSuppliersPageDispatcher, RouteComponentProps {
  isMyTasks?: boolean,
}

export const getCorrectProperyForColumn = {
  material: "material_number",
  materialDescription: "material_number",
  plant: "plant",
  supplier: "parent_vendor",
  supplierPartId: "part_id",
  shortDescription: "short_description",
  scopeOfApplication: "item_description",
  keywords: "key_words",
  manufacturerName: "manufacturer_name",
  manufacturerPartNo: "manufacturer_part_number",
}

function NewSearchComponentWithCategory({ mainTab, onSearch }) {
  const classes2 = useStylesForToolTip({});
  const [categorySelected, setCategorySelected] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [apiCallMade, setApiCallMade] = React.useState(false);
  const [releasedFeatures, setReleasedFeatures] = React.useState<any>(features); const hasFeature = (feature): boolean => releasedFeatures[feature];

  function getOptions() {
    if (mainTab === SupplierTabs.MATERIALS) {
      if (hasFeature(Features.SEARCH_ALL_PSL))
        return materialSearchOptions;

      return materialSearchNotAllOptions;
    } else {
      if (hasFeature(Features.SEARCH_ALL_PSL))
        return pslSearchOptions

      return pslSearchNotAllOptions;
    }
  }
  function handleChange(v) {

    if (apiCallMade && !v) {
      onSearch(null, '');
      setApiCallMade(false)
    }
    setCategorySelected(v);
    setSearchValue('');
  }

  React.useEffect(() => {
    setCategorySelected(null);
    setSearchValue('');
    setApiCallMade(false)
  }, [mainTab])

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      onSearch(categorySelected, searchValue.trim());
      setApiCallMade(true)
    }
  };

  return <div style={{ display: "flex" }}>
    <div style={{ width: "210px", zIndex: 12 }}>
      {<CustomFastSelect
        placeholder={'Select Category'}
        options={getOptions()}
        initialValue={categorySelected}
        onChange={(v) => handleChange(v)}
        changeBorderRadius={true} />
      }</div>
    <Tooltip
      classes={{ tooltip: classes2.customTooltip }}
      title={!categorySelected ? "Select a category" : ""}
    >
      <StyledPaper elevation={0} style={{ borderRadius: "0px 4px 4px 0px", height: "38px", width: "200px" }} >
        <StyledInput
          inputProps={{ 'aria-label': 'Search...' }}
          disabled={!categorySelected}
          value={searchValue}
          onKeyUp={handleKeyUp}
          onChange={e => setSearchValue(e.target.value)}
        />
        <StyledIconButton aria-label="Search" onClick={

          !searchValue ? () => { } :
            () => {
              setSearchValue(searchValue.trim())
              onSearch(categorySelected, searchValue.trim())
              setApiCallMade(true)
            }}>
          <SearchIcon />
        </StyledIconButton>
      </StyledPaper>
    </Tooltip>
  </div>
}

export const getMaterialPslSearchValues = (mainTab: SupplierTabs, catergoryTerm: { label: string, value: string } | null, searchTerm: string) => {
  let materialSearch = null
  let pslSearch = null

  if (catergoryTerm?.value && searchTerm) {
    if (mainTab === SupplierTabs.MATERIALS) {
      materialSearch = {};
      materialSearch[catergoryTerm?.value] = searchTerm
    } else {
      pslSearch = {}
      pslSearch[catergoryTerm?.value] = searchTerm;
    }
  }

  return [materialSearch, pslSearch];
}

export const checkPslExactId = (id: string) => {
  let tempId: any = id;
  const reg: RegExp = /^([a-z]{1})([0-9]+)$/i; // checks if only first char is char and rest are numbers eq. L1234
  const allowedPrefixes = ["P", "L", "C"];
  if (reg.test(id) && allowedPrefixes.includes(id?.charAt(0)?.toUpperCase())) {
    tempId = id.substr(1);
  }
  return tempId
}

export default function PreferredSuppliersPage(props: PreferredSuppliersProps) {
  const MAX_PSL_ITEMS_TO_DOWNLOAD_EXCEL = 10000;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const format = props.user.profileSettings.dateTimeFormat;
  const userRole = props.user.getUserRole();
  const isRequestor = props.user.isRequestor()
  const isAtMarket = props.user.isAtMarket();
  const isViewOnly = props.user.isViewOnly();

  const [notificationMessage, setNotificationMessage] = React.useState<string>(null);
  const message: string = useSelector((state: RootStateStore) => state.shared.notifications.message);
  console.log("message", message)
  useEffect(() => {
    if (message != null) {
      console.log("message1", message)
      setNotificationMessage(message)
    }
  }, [message]);

  const { data: languages } = useFetchLanguages()
  const { mutateAsync: saveMaterialColumns } = useSaveMaterialsColumns();
  const { mutateAsync: saveVendorColumns } = useSaveVendorsColumns();
  const { mutateAsync: saveMyTasksColumns } = useSavePslMyTasksColumns();
  const { isMyTasks } = props;
  const { urlTaskTab = '' } = props.match?.params as any;
  const paginationOptions = [10, 25, 50, 100, 200];

  const isPslTab = () => mainTab === SupplierTabs.PSL;
  const isMyTasksTab = () => mainTab === SupplierTabs.MY_TASKS;
  const isMaterialsTab = () => mainTab === SupplierTabs.MATERIALS;

  const [mainTab, setMainTab] = React.useState<SupplierTabs>(null);
  const [filterTab, setFilterTab] = React.useState<SupplierFilterTabs>(SupplierFilterTabs.ACTIVE);
  const [taskTab, setTaskTab] = React.useState<PslComplianceStatus>(null);
  const [localizeTab, setLocalizeTab] = React.useState<PslComplianceStatus>(PslComplianceStatus.canLocalize);
  const [toggleFilter, setToggleFilter] = React.useState<boolean>(false);
  const [syncPslColumns, setSyncPslColumns] = React.useState<any>(() => GetPslColumns(userRole, props.user.filters.frontendLists.PslVendors));
  const [syncMyTasksColumns, setSyncMyTasksColumns] = React.useState<any>(() => GetMyTasksColumns(userRole, props.user.filters.frontendLists.PslMyTasks, (taskTab === PslComplianceStatus.Localization || taskTab === PslComplianceStatus.CannotLocalize || taskTab === PslComplianceStatus.canLocalize)));
  const [syncMaterialColumns, setSyncMaterialColumns] = React.useState<any>(() => GetMaterialColumns(userRole, props.user.filters.frontendLists.PslMaterials));
  const [exactInputId, setExactInputId] = React.useState(null);
  const [exactIdSearch, setExactIdSearch] = React.useState(null);
  const [selectedTaskPslIds, setSelectedTaskPslIds] = React.useState<number[]>([]);
  const [anchorTaskMenu, setAnchorTaskMenu] = React.useState<null | HTMLElement>(null);
  const [openApprove, setOpenApprove] = React.useState<boolean>(false);
  const [openReject, setOpenReject] = React.useState<{ popup: boolean, reason: string }>({ popup: false, reason: null });
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const [openMaterialDelete, setOpenMaterialDelete] = React.useState<boolean>(false);
  const [openReactivate, setOpenReactivate] = React.useState<boolean>(false);
  const [openChildReactivate, setOpenChildReactivate] = React.useState<boolean>(false);
  const [psl, setPsl] = React.useState<ISupplier>(null);
  const [approvalTab, setApprovalTab] = React.useState<boolean>(false);
  const [isFavouriteActive, setIsFavouriteActive] = React.useState<boolean>(false);
  const [filtersHidden, setFiltersHidden] = React.useState<boolean>(false);
  const [pslHasFilters, setPslHasFilters] = React.useState<boolean>(true);

  const UserScopeCompanies: Array<any> = props.user.filters.userScope.companies.map(el => ({ ...el, alwaysVisible: true }));
  const UserScopeMarkets: Array<any> = props.user.filters.userScope.markets.map(el => ({ ...el, alwaysVisible: true }));
  const UserScopeSpendCatL1: Array<any> = props.user.filters.userScope.spendCatL1s.map(el => ({ ...el, alwaysVisible: true, showAdd: false }));
  const UserScopeSpendCatL2: Array<any> = props.user.filters.userScope.spendCatL2s.map(el => ({ ...el, alwaysVisible: true, showAdd: false }));
  const UserScopeSpendCatL3: Array<any> = props.user.filters.userScope.spendCatL3s.map(el => ({ ...el, alwaysVisible: true }));
  const isApprover = props.user.canApprove();
  const isApproverInApproval = (isApprover && approvalTab) || (isApprover && isRequestor && mainTab === SupplierTabs.MY_TASKS);
  const [isFromUrl, setIsFromUrl] = React.useState(false)
  const { hasFeature } = React.useContext(FeaturesContext);
  const filterContainer = React.useRef(null);
  const tableRef = React.useRef(null);
  const [searchCategory, setSearchCategory] = React.useState<{ label: string, value: string } | null>((hasFeature(Features.SEARCH_ALL_PSL) && hasFeature(Features.psl_162778_search_state_mgmt)) ? { label: 'All', value: 'all' } : null);
  const [searchInputTerm, setsearchInputTerm] = React.useState<string>("");
  const [isViewChildrenMode, setIsViewChildrenMode] = React.useState<boolean>(false);

  const getMainTabBasedOnUrl = (isMytasks: boolean) => {
    if (isMytasks) return SupplierTabs.MY_TASKS;
    return SupplierTabs.MATERIALS;
  };

  const getTaskTabBasedOnUrl = (isMyTasks, urlTaskTab = ''): PslComplianceStatus => {
    if (isMyTasks && MyTasksAtMarketTabs[urlTaskTab.toUpperCase()]) {
      hasFeature(Features.OPEN_LOCALIZATION_FROM_EMAIL) && setIsFromUrl(true);
      switch (MyTasksAtMarketTabs[urlTaskTab.toUpperCase()]) {
        case MyTasksAtMarketTabs.COMPLIANCE:
          return PslComplianceStatus.Compliance;
        case MyTasksAtMarketTabs.LOCALIZATION:
          return PslComplianceStatus.Localization;
        case MyTasksAtMarketTabs.APPROVAL:
          return PslComplianceStatus.Overall;
        case MyTasksAtMarketTabs.CANLOCALIZATION:
          return PslComplianceStatus.canLocalize;
        case MyTasksAtMarketTabs.CANNOTLOCALIZATION:
          return PslComplianceStatus.CannotLocalize;
        case MyTasksAtMarketTabs.JUSTIFIED:
          return PslComplianceStatus.Justified
        case MyTasksAtMarketTabs.NOTJUSTIFIED:
          return PslComplianceStatus.NotJustified
        default:
          return null;
      }
    }
    else
      setIsFromUrl(false)

    if (isMyTasks) return PslComplianceStatus.Compliance;
    return null;
  };

  useEffect(() => {
    if (mainTab === SupplierTabs.MY_TASKS) {
      let isLocalizedList: boolean = taskTab === PslComplianceStatus.Localization || taskTab === PslComplianceStatus.CannotLocalize || taskTab === PslComplianceStatus.canLocalize
      setSyncMyTasksColumns(() => GetMyTasksColumns(userRole, props.user.filters.frontendLists.PslMyTasks, isLocalizedList));
    }
  }, [taskTab]);

  const highlightTextinTable = (highlight: string) => {
    const markInstance = new Mark(tableRef.current);
    if (hasFeature(Features.PSL_163770_VIEW_CHILD_PSL_FROM_PARENT)) {
      if (!isViewChildrenMode) {
          markInstance.mark(highlight, { element: 'span', className: 'highlight', exclude: ["thead *"] });
      } else {
        markInstance.unmark();
      }
    } else {
      markInstance.unmark({
        done: () => {
          highlight === '' ?
            markInstance.unmark(highlight, { element: 'mark', className: 'highlight', exclude: ["thead *"] })
            : markInstance.mark(highlight, { element: 'mark', className: 'highlight', exclude: ["thead *"] });
        }
      });
    }
  }

  useEffect(() => {
    // below is needed to ensure exact id is not shown when view children button is clicked
    if (hasFeature(Features.PSL_163770_VIEW_CHILD_PSL_FROM_PARENT)) {
      if (props.filters["childrenOf"]?.selected.toString().length > 0) {
        setIsViewChildrenMode(true);
      } else {
        setIsViewChildrenMode(false);
      }
    }

  }, [props.filters]);

  useEffect(() => {
    if (props.list.length > 0 && hasFeature(Features.PSL_163152_SEARCHALL_HIGHLIGHT)) {
      if (searchCategory?.value) {
        if (searchInputTerm !== '') {
          enableAllColumnVisiblity(searchCategory.value);
        } else {
          resetDefaultColumn();
        }
        // if (searchCategory?.value === 'all') {
        setTimeout(() => {
          highlightTextinTable(searchInputTerm);
        }, 1000)
        // }
      } else {
        resetDefaultColumn();
        highlightTextinTable("");
      }
    }
  }, [props.list]);

  const enableAllColumnVisiblity = (category: string) => {
    if (mainTab === SupplierTabs.MATERIALS) {
      if (category === 'all') {
        let hardCpyClmn = JSON.parse(JSON.stringify(syncMaterialColumns));
        hardCpyClmn.forEach((element) => {
          element.hidden = false;
          element.show = true;
        });

        setSyncMaterialColumns(() => hardCpyClmn);
      } else {
        let hardCpyClmn = JSON.parse(JSON.stringify(GetMaterialColumns(userRole, props.user.filters.frontendLists.PslMaterials)));
        hardCpyClmn.forEach((element) => {
          if (element.id === getCorrectProperyForColumn[category]) {
            element.hidden = false;
            element.show = true;
          }
        });

        setSyncMaterialColumns(() => hardCpyClmn);
      }
    } else if (mainTab === SupplierTabs.PSL) {
      if (category === 'all') {
        let hardCpyClmn = JSON.parse(JSON.stringify(syncPslColumns));
        hardCpyClmn.forEach((element) => {
          element.hidden = false;
          element.show = true;
        });

        setSyncPslColumns(() => hardCpyClmn);
      } else {
        let hardCpyClmn = JSON.parse(JSON.stringify(GetPslColumns(userRole, props.user.filters.frontendLists.PslVendors)));
        hardCpyClmn.forEach((element) => {
          if (element.id === getCorrectProperyForColumn[category]) {
            element.hidden = false;
            element.show = true;
          }
        });

        setSyncPslColumns(() => hardCpyClmn);
      }
    } else if (mainTab === SupplierTabs.MY_TASKS) {
      if (category === 'all') {
        let hardCpyClmn = JSON.parse(JSON.stringify(syncMyTasksColumns));
        hardCpyClmn.forEach((element) => {
          element.hidden = false;
          element.show = true;
        });

        setSyncMyTasksColumns(() => hardCpyClmn);
      } else {
        let hardCpyClmn = JSON.parse(JSON.stringify(GetMyTasksColumns(userRole, props.user.filters.frontendLists.PslMyTasks, (taskTab === PslComplianceStatus.Localization || taskTab === PslComplianceStatus.CannotLocalize || taskTab === PslComplianceStatus.canLocalize))));
        hardCpyClmn.forEach((element) => {
          if (element.id === getCorrectProperyForColumn[category]) {
            element.hidden = false;
            element.show = true;
          }
        });

        setSyncMyTasksColumns(() => hardCpyClmn);
      }
    }
  }

  const resetDefaultColumn = () => {
    if (mainTab === SupplierTabs.MATERIALS) {
      setSyncMaterialColumns(() => GetMaterialColumns(userRole, props.user.filters.frontendLists.PslMaterials))
    } else if (mainTab === SupplierTabs.PSL) {
      setSyncPslColumns(() => GetPslColumns(userRole, props.user.filters.frontendLists.PslVendors));
    } else if (mainTab === SupplierTabs.MY_TASKS) {
      setSyncMyTasksColumns(() => GetMyTasksColumns(userRole, props.user.filters.frontendLists.PslMyTasks, (taskTab === PslComplianceStatus.Localization || taskTab === PslComplianceStatus.CannotLocalize || taskTab === PslComplianceStatus.canLocalize)));
    }
  }

  async function pslAndMaterialsSearch(catergoryTerm, searchTerm) {
    setSearchCategory(catergoryTerm);
    setsearchInputTerm(searchTerm);

    const [materialSearch, pslSearch] = await getMaterialPslSearchValues(mainTab, catergoryTerm, searchTerm);

    await props.init(mainTab, userRole, exactIdSearch, null, pslSearch, materialSearch);
  }

  const buildAllFilters = (filters) => ({
    ...filters,
    CompanyCode: {
      ...filters.CompanyCode,
      selected: R.uniqBy(R.prop('id'), UserScopeCompanies.concat(filters.CompanyCode.selected))
    },
    Markets: {
      ...filters.Markets,
      selected: R.uniqBy(R.prop('id'), UserScopeMarkets.concat(filters.Markets.selected))
    },
    SpendCat1Code: {
      ...filters.SpendCat1Code,
      showAdd: UserScopeSpendCatL1.length <= 0,
      selected: R.uniqBy(R.prop('id'), UserScopeSpendCatL1.concat(filters.SpendCat1Code.selected))
    },
    SpendCat2Code: {
      ...filters.SpendCat2Code,
      showAdd: UserScopeSpendCatL2.length <= 0,
      selected: R.uniqBy(R.prop('id'), UserScopeSpendCatL2.concat(filters.SpendCat2Code.selected))
    },
    SpendCat3Code: {
      ...filters.SpendCat3Code,
      showAdd: UserScopeSpendCatL3.length <= 0,
      selected: R.uniqBy(R.prop('id'), UserScopeSpendCatL3.concat(filters.SpendCat3Code.selected))
    }
  });

  const allFilters = buildAllFilters(props.filters);
  const PslPanelColumns = GetMaterialTableColumns(GetDefaultMaterialsColumns(null), format, isRequestor, t, languages, props.user.pslExpiryDays);

  useEffect(() => {

    const pslDefaultOptions = JSON.parse(localStorage.getItem("pslDefaultOptions"));
    if (hasFeature(Features.PSL_163766_CACHE_SEARCH_OPTIONS) && pslDefaultOptions !== null) {
      setMainTab(pslDefaultOptions.mainTab !== null ? pslDefaultOptions.mainTab : getMainTabBasedOnUrl(isMyTasks));
      setFilterTab(pslDefaultOptions.filterTab);
      setTaskTab(pslDefaultOptions.taskTab);
      setLocalizeTab(pslDefaultOptions.localizeTab)

      let tempId = getParsedId(pslDefaultOptions.exactInputId);
      setExactIdSearch(tempId ? parseInt(tempId) : null);
      setExactInputId(pslDefaultOptions?.exactInputId ? pslDefaultOptions.exactInputId : null);
    } else {
      setMainTab(getMainTabBasedOnUrl(isMyTasks));
    }

    setTaskTab(getTaskTabBasedOnUrl(isMyTasks, urlTaskTab))
  }, []);

  useEffect(() => {
    if (hasFeature(Features.PSL_163766_CACHE_SEARCH_OPTIONS)) {
      const options = { mainTab, filterTab, taskTab, localizeTab, exactInputId };
      localStorage.setItem("pslDefaultOptions", JSON.stringify(options))
    }
  }, [mainTab, filterTab, taskTab, localizeTab, exactInputId]);

  const isApproverList = taskTab === PslComplianceStatus.Overall || taskTab === PslComplianceStatus.Justified || taskTab === PslComplianceStatus.NotJustified;

  function toggleDrawer(open: boolean, toggle: (boolean) => void): (event: React.KeyboardEvent | React.MouseEvent) => any {
    return (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
        return;
      }
      toggle(open);
    }
  }

  const toggleFilterDrawer = toggleDrawer(true, setToggleFilter);

  const toggleIdSearch = (id: number) => {
    const [materialSearch, pslSearch] = getMaterialPslSearchValues(mainTab, searchCategory, searchInputTerm);

    props.init(mainTab, userRole, id, null, pslSearch, materialSearch, isFavouriteActive)
  };

  const getParsedId = (id: any) => {
    let tempId: any = id;
    // const reg: RegExp = /^([a-z]{1})([0-9]+)$/i; // checks if only first char is char and rest are numbers eq. L1234
    // const allowedPrefixes = ["P", "L", "C"];
    // if (reg.test(id) && allowedPrefixes.includes(id?.charAt(0)?.toUpperCase())) {
    //   tempId = id.substr(1);
    // }

    return tempId?.replace(/[a-zA-Z]+/,'');
  }

  const onIdSearch = (id: any) => {
    let tempId = getParsedId(id);
    if (id && parseInt(tempId)) {
      setExactIdSearch(parseInt(tempId));
      setExactInputId(id);
      setSelectedTaskPslIds([]);
      toggleIdSearch(parseInt(tempId));
      props.history.push({
        pathname: '/psl',
        search: '?id=' + id
      })
    }
  };
  const onDropIdSearch = () => {
    setExactIdSearch(null);
    setExactInputId(null);
    toggleIdSearch(null);
    props.history.push({ pathname: '/psl' })
  };
  const closeTaskMenu = () => {
    setAnchorTaskMenu(null);
  };

  const fetchList = () => toggleIdSearch(null);
  const fetchChildrenOfList = (parentPslId: number, parentVendorName: string, parentVendorCode: string) => {
    const filters = R.set(R.lensPath(["childrenOf", 'selected']), { title: parentVendorCode + " - " + parentVendorName, id: parentPslId }, props.filters)
    props.updateFilters(R.mergeDeepWithKey((k, l, r) => k === 'data' ? r : l, filters, props.filters), mainTab, isApproverInApproval);
  }

  const onApprove = () => {
    if (selectedTaskPslIds.length > 0) {
      props.approvePSLs(selectedTaskPslIds, fetchList);
      setSelectedTaskPslIds([]);
      setOpenApprove(false);
    }
  }
  const onReject = () => {
    if (selectedTaskPslIds.length > 0 && openReject.reason) {
      props.rejectPSLs(selectedTaskPslIds, openReject.reason, fetchList);
      setSelectedTaskPslIds([]);
      setOpenReject({ popup: false, reason: null });
    }
  }
  const onStartApprove = () => {
    setAnchorTaskMenu(null);
    setOpenApprove(true);
  }
  const onStartReject = () => {
    setAnchorTaskMenu(null);
    setOpenReject({ popup: true, reason: null });
  }
  const onDelete = () => {
    if (selectedTaskPslIds.length > 0) {
      props.deletePSLs(selectedTaskPslIds, fetchList);
      setSelectedTaskPslIds([]);
      setOpenDelete(false);
    }
  }
  const onMaterialDelete = () => {
    if (selectedTaskPslIds.length > 0) {
      props.deleteMaterialPSLs(selectedTaskPslIds, fetchList);
      setSelectedTaskPslIds([]);
      setOpenMaterialDelete(false);
    }
  }
  const onDeleteStart = (pslId: number) => {
    setSelectedTaskPslIds([pslId])
    setOpenDelete(true)
  }
  const onMaterialDeleteStart = (materialId: number) => {
    setSelectedTaskPslIds([materialId])
    setOpenMaterialDelete(true)
  }
  const onReactivateStart = (psldata: ISupplier) => {
    if (new PSL(psldata).isChildPsl()) {
      psldata.validFrom = DateTime.local().toString()
      psldata.validTo = DateTime.local().toString()
      setPsl(psldata)
      setOpenChildReactivate(true)
    } else {
      if (hasFeature(Features.PSL_REACTIVATION_WITH_ALL_FIELDS)) {
        props.history.push("/psl/reactivate/" + psldata.pslId)
      } else {
        psldata.validFrom = DateTime.local().toString()
        setPsl(psldata)
        setOpenReactivate(true)
      }
    }
  }
  const [editForm, showEditForm] = React.useState<boolean>(false);
  const onReactivatePslStart = (pslId: number) => props.fetchPsl(pslId, () => {
    showEditForm(true)
  })
  useEffect(() => {
    if (props.pslToEdit && editForm) {
      props.pslToEdit.validFrom = DateTime.local().toString()
      if (props.pslToEdit.validTo && props.pslToEdit.validTo < DateTime.local().toFormat("yyyy-MM-ddTHH:mm:ss")) {
        props.pslToEdit.validTo = DateTime.local().toString()
      }
      setPsl(props.pslToEdit)
      setOpenReactivate(true)
      showEditForm(false)
    }
  }, [editForm, props.pslToEdit])

  // useEffect(() => {
  //   if(!filtersHidden) {
  //     let hasFilters = filterContainer.current.querySelector('div > div > div:nth-child(1)').hasChildNodes();
  //     setPslHasFilters(hasFilters);
  //   }
  // }, [allFilters])

  async function setMaterialColumns(columns: MaterialColumnsList[]) {
    setSyncMaterialColumns(() => columns);
    await saveMaterialColumns(columns);
    dispatch(new SavePslMaterialsColumnsSuccess(columns));
  }

  async function setVendorColumns(columns: PslVendorColumnsList[]) {
    setSyncPslColumns(() => columns);
    await saveVendorColumns(columns);
    dispatch(new SavePslVendorsColumnsSuccess(columns));
  }

  async function setMyTasksColumns(columns: PslVendorColumnsList[]) {
    setSyncMyTasksColumns(() => columns);
    await saveMyTasksColumns(columns);
    dispatch(new SavePslMyTasksColumnsSuccess(columns));
  }

  const getCorrectDataForEachTabs = () => {
    const materialTabHeading = "Material Columns";
    const pslTabHeading = "PSL Columns";
    const myTaskTabHeading = "My Task Columns";
    switch (mainTab) {
      case SupplierTabs.MATERIALS:
        return [syncMaterialColumns, () => GetDefaultMaterialsColumns(userRole), setMaterialColumns, materialTabHeading];
      case SupplierTabs.PSL:
        return [syncPslColumns, () => GetDefaultPslColumns(userRole), setVendorColumns, pslTabHeading];
      case SupplierTabs.MY_TASKS:
      default:
        return [syncMyTasksColumns, () => GetDefaultMyTasksColumns(userRole), setMyTasksColumns, myTaskTabHeading];
    }
  };

  const [existingColumns, defaultColumns, setColumns, ColumnHeading] = getCorrectDataForEachTabs();

  useEffect(() => {
    if (hasFeature(Features.PSL_164503_AUTO_RESTORE_COLUMN) && existingColumns?.length !== defaultColumns().length) {
      setColumns(defaultColumns())
    }
  }, [mainTab]);

  const onReactivate = () => {
    if (psl.validTo && psl.validTo < DateTime.local().toFormat("MM/dd/yyyy HH:mm:ss")) {
      psl.validTo = DateTime.local().toString()
    }
    props.newReactivatePSL({ pslId: psl['pslId'], validTo: psl['validTo'] }, fetchList)
    setOpenReactivate(false)
    setOpenChildReactivate(false)
  }

  const getQueryKey = (key: string): number => props.query[key];
  const MAX_EXCEL_ROW_ENTRIES = 100000;

  const validateSearchCatOption = (currentTab: SupplierTabs) => {
    let category = getOptions(currentTab);
    if (searchCategory?.value) {
      let hasCat = category.findIndex((e) => e.value === searchCategory.value);

      if (hasCat === -1) {
        setSearchCategory(null);
        setsearchInputTerm("");
      }
    }
  }

  function getOptions(currentTab: SupplierTabs = mainTab) {
    if (currentTab === SupplierTabs.MATERIALS) {
      if (hasFeature(Features.SEARCH_ALL_PSL)) {
        if (hasFeature(Features.PSL_162445_ADD_PLANT_FILTER_AND_SEARCH))
          return newMaterialSearchOptions;
        else
          return materialSearchOptions;
      }
      if (hasFeature(Features.PSL_162445_ADD_PLANT_FILTER_AND_SEARCH))
        return newMaterialSearchNotAllOptions;
      return materialSearchNotAllOptions;
    } else {
      if (hasFeature(Features.SEARCH_ALL_PSL))
        return pslSearchOptions

      return pslSearchNotAllOptions;
    }
  }

  return (
    <>
      <Helmet>
        <title>Suppliers</title>
      </Helmet>

      <PslTabsComponent
        listLoading={props.loading}
        localizeTab={localizeTab}
        setLocalizeTab={setLocalizeTab}
        mainTab={mainTab}
        setMainTab={setMainTab}
        filterTab={filterTab}
        setFilterTab={setFilterTab}
        approvalTab={approvalTab}
        setApprovalTab={setApprovalTab}
        taskTab={taskTab}
        setTaskTab={setTaskTab}
        filters={props.filters}
        setSelectedTaskPslIds={setSelectedTaskPslIds}
        exactIdSearch={exactIdSearch}
        isFromUrl={isFromUrl}
        setIsFromUrl={setIsFromUrl}
        user={props.user}
        isFavouriteActive={isFavouriteActive}
        searchCategory={searchCategory}
        searchInputTerm={searchInputTerm}
        validateSearchCatOption={validateSearchCatOption}
      >
        <SearchFilterWrapper>
          {(hasFeature(Features.PSL_163770_VIEW_CHILD_PSL_FROM_PARENT) && !isViewChildrenMode) && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <div style={{ paddingRight: '10px', display: 'flex' }}>
              <InputWithIcon placeholder={"PSL ID"} width={168} controlledValue={exactInputId}
                onAction={(id: any) => onIdSearch(id)} icon={<GpsIcon />} />
              {(exactInputId !== null) && <Button style={{ margin: '0 10px' }} onClick={onDropIdSearch} color="secondary">CLEAR</Button>}
            </div>
            {
              hasFeature(Features.REFACTOR_S2P) &&
              <GenericSearchCategoryComponent
                searchOptions={getOptions()}
                onSearch={pslAndMaterialsSearch}
                width={"220px"}
                initialCategory={searchCategory}
                initialSearch={searchInputTerm}
              />
            }
            {
              !hasFeature(Features.REFACTOR_S2P) &&
              <NewSearchComponentWithCategory
                mainTab={mainTab}
                onSearch={pslAndMaterialsSearch}
              />
            }

          </div>}
          {!hasFeature(Features.PSL_163770_VIEW_CHILD_PSL_FROM_PARENT) && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <div style={{ paddingRight: '10px', display: 'flex' }}>
              <InputWithIcon placeholder={"PSL ID"} width={168} controlledValue={exactInputId}
                onAction={(id: any) => onIdSearch(id)} icon={<GpsIcon />} />
              {(exactInputId !== null) && <Button style={{ margin: '0 10px' }} onClick={onDropIdSearch} color="secondary">CLEAR</Button>}
            </div>
            {
              hasFeature(Features.REFACTOR_S2P) &&
              <GenericSearchCategoryComponent
                searchOptions={getOptions()}
                onSearch={pslAndMaterialsSearch}
                width={"220px"}
                initialCategory={searchCategory}
                initialSearch={searchInputTerm}
              />
            }
            {
              !hasFeature(Features.REFACTOR_S2P) &&
              <NewSearchComponentWithCategory
                mainTab={mainTab}
                onSearch={pslAndMaterialsSearch}
              />
            }

          </div>}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <FilterButton onClick={toggleFilterDrawer} />
            {hasFeature(Features.REFACTOR_S2P) ?
              <GenericColumnsConfigComponent
                initialColumns={existingColumns}
                DefaultColumns={defaultColumns}
                setColumns={setColumns}
                heading={ColumnHeading}
                labelForTranslation={"psl_columns"}
                iconStyle={false}
              /> :
              <ColumnsConfigComponent
                userRole={userRole}
                mainTab={mainTab}
                setMaterialColumns={setMaterialColumns}
                materialColumns={syncMaterialColumns}
                vendorColumns={syncPslColumns}
                setVendorColumns={setVendorColumns}
                setMyTasksColumns={setMyTasksColumns}
                myTasksColumns={syncMyTasksColumns}
              />
            }
            {isAtMarket && !isMyTasksTab() && !isViewOnly && (
              <>
                <GenericIconButtonsComponent
                  componentName={"Add New"}
                  onClick={() => props.history.push("/psl/create")}
                />
              </>
            )}

            {isAtMarket && isMaterialsTab() && !isViewOnly && (
              <>
                <GenericIconButtonsComponent
                  componentName={"Import"}
                  onClick={() => props.history.push("/psl/import")}
                />
              </>
            )}
            {isRequestor && hasFeature(Features.PSL_169580_SPENDER_VIEW_TOGGLE_BTN) &&
              <SpenderViewBtns />
            }
            {!isPslTab() && (isAtMarket || isApprover) && !isViewOnly && !isRequestor && (
              <>
                <GenericIconButtonsComponent
                  componentName={"Export to Excel"}
                  disabled={props.totalCount > MAX_EXCEL_ROW_ENTRIES}
                  loading={props.exporting}
                  onClick={
                    props.totalCount > MAX_EXCEL_ROW_ENTRIES
                      ? () => { }
                      : () => {
                        props.totalCount < MAX_PSL_ITEMS_TO_DOWNLOAD_EXCEL
                          ? props.exportPslListData({
                            ...props.query,
                            filters: allFilters
                          })
                          : props.exportPslListDataByEmail({
                            ...props.query,
                            filters: allFilters
                          });
                      }
                  }
                />
              </>
            )}
          </div>
        </SearchFilterWrapper>
      </PslTabsComponent>

      <p style={{ color: '#f19002', float: "right", margin: '4px 2%', display: 'flex', alignItems: 'center', gap: '5px' }}>
        {(hasFeature(Features.PSL_FAVORITE) && !isMyTasksTab()) &&
          <div>
            <Switch checked={isFavouriteActive}
              onChange={(checked) => {
                const [materialSearch, pslSearch] = getMaterialPslSearchValues(mainTab, searchCategory, searchInputTerm);

                props.init(mainTab, userRole, exactIdSearch, null, pslSearch, materialSearch, checked);
                setIsFavouriteActive(checked)
              }}
              style={{ backgroundColor: isFavouriteActive ? '#f19002' : '#b5b7ba', marginRight: '4px' }} /> My Favourites
          </div>
        }

        {(hasFeature(Features.MY_TASK_STATIC_FILTER) && pslHasFilters) &&
          <Button onClick={() => setFiltersHidden(!filtersHidden)} color={!filtersHidden ? "primary" : "secondary"} variant="outlined">
            {filtersHidden ? 'SHOW FILTERS' : 'HIDE FILTERS'}
          </Button>
        }
      </p>

      {!filtersHidden ?
        <div ref={filterContainer}>
          <PslActiveFiltersList
            onAddFilter={toggleFilterDrawer}
            filters={allFilters}
            isApproverInApproval={isApproverInApproval}
            mainTab={mainTab}
          /> </div> : <div style={{ margin: "4%" }}></div>}

      {selectedTaskPslIds.length > 0 &&
        openDelete === false &&
        openMaterialDelete === false && (
          <div style={{ padding: "0 0 20px 30px" }}>
            <Button
              color="secondary"
              variant="contained"
              style={{ width: "110px" }}
              endIcon={<ArrowDropDownIcon />}
              onClick={event => setAnchorTaskMenu(event.currentTarget)}
            >
              Actions
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorTaskMenu}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(anchorTaskMenu)}
              onClose={closeTaskMenu}
            >
              <MenuItem onClick={onStartApprove}>Approve</MenuItem>
              <MenuItem onClick={onStartReject}>Reject</MenuItem>
            </Menu>
          </div>
        )}
      <div ref={tableRef}>
        {isPslTab() && (
          <PslTable
            isLocalizedList={false}
            showBorder={!!exactIdSearch}
            userColumns={syncPslColumns}
            setSelectedCheckboxes={null}
            user={props.user}
            loading={props.loading}
            data={props.loading ? [] : [...props.list]}
            totalCount={props.totalCount}
            currentPage={props.currentPage}
            pageSize={props.pageSize}
            paginationOptions={paginationOptions}
            onNewParent={(parentId, parentVendorName, parentVendorId) =>
              fetchChildrenOfList(parentId, parentVendorName, parentVendorId)
            }
            onNewPage={page =>
              props.newPage(page, SupplierTabs.PSL, isApproverInApproval)
            }
            onNewPageSize={pageSize =>
              props.newPageSize(pageSize, SupplierTabs.PSL, isApproverInApproval)
            }
            onNewOrder={(field, dir) =>
              props.newOrder(field, dir, SupplierTabs.PSL, isApproverInApproval)
            }
            pslPanelColumns={PslPanelColumns}
            pslPanelData={props.materials}
            isApproverList={false}
            showDeleted={filterTab === SupplierFilterTabs.ARCHIVED}
            onDelete={onDeleteStart}
            onReactivate={onReactivateStart}
            onMaterialDelete={onMaterialDeleteStart}
            mainTab={mainTab}
          />
        )}
        {isMaterialsTab() && (
          <PslMaterialTable
            showBorder={!!exactIdSearch}
            showMore={true}
            languages={languages}
            userColumns={syncMaterialColumns}
            user={props.user}
            loading={props.loading}
            data={props.loading ? [] : [...props.list]}
            totalCount={props.totalCount}
            currentPage={props.currentPage}
            pageSize={props.pageSize}
            paginationOptions={paginationOptions}
            onNewPage={page =>
              props.newPage(page, SupplierTabs.MATERIALS, isApproverInApproval)
            }
            onNewPageSize={pageSize =>
              props.newPageSize(
                pageSize,
                SupplierTabs.MATERIALS,
                isApproverInApproval
              )
            }
            onNewOrder={(field, dir) =>
              props.newOrder(
                field,
                dir,
                SupplierTabs.MATERIALS,
                isApproverInApproval
              )
            }
            onDelete={onMaterialDeleteStart}
            onReactivate={onReactivatePslStart}
          />
        )}
        {isMyTasksTab() && (
          <PslTable
            isLocalizedList={taskTab === PslComplianceStatus.Localization || taskTab === PslComplianceStatus.CannotLocalize || taskTab === PslComplianceStatus.canLocalize}
            setSelectedCheckboxes={setSelectedTaskPslIds}
            showBorder={!!exactIdSearch}
            userColumns={syncMyTasksColumns}
            user={props.user}
            loading={props.loading}
            data={props.loading ? [] : [...props.list]}
            totalCount={props.totalCount}
            currentPage={getQueryKey("page")}
            pageSize={getQueryKey("top")}
            paginationOptions={paginationOptions}
            onNewParent={() => void 0}
            onNewPage={page => props.newPage(page, SupplierTabs.PSL)}
            onNewPageSize={pageSize => props.newPageSize(pageSize, SupplierTabs.PSL)}
            onNewOrder={(field, dir) => props.newOrder(field, dir, SupplierTabs.PSL)}
            pslPanelColumns={PslPanelColumns}
            pslPanelData={props.materials}
            isApproverList={isApproverList}
            showDeleted={false}
            onDelete={onDeleteStart}
            onMaterialDelete={onMaterialDeleteStart}
            mainTab={mainTab}
            urlTaskTab={urlTaskTab}
          />
        )}
      </div>
      <ConfirmDialog
        open={openApprove}
        title={"Approve PSL's"}
        confirmText={"Do you want to approve selected PSL's?"}
        onOK={onApprove}
        okButtonText={"OK"}
        onCancel={() => setOpenApprove(false)}
        cancelButtonText={"Cancel"}
      />
      <ConfirmDialog
        open={openDelete}
        title={"Delete PSL"}
        confirmText={"Do you want to delete the PSL form?"}
        onOK={onDelete}
        okButtonText={"OK"}
        onCancel={() => {
          setSelectedTaskPslIds([]);
          setOpenDelete(false);
        }}
        cancelButtonText={"Cancel"}
      />
      <ConfirmDialog
        open={openMaterialDelete}
        title={"Delete Material"}
        confirmText={"Do you want to delete the Material?"}
        onOK={onMaterialDelete}
        okButtonText={"OK"}
        onCancel={() => {
          setSelectedTaskPslIds([]);
          setOpenMaterialDelete(false);
        }}
        cancelButtonText={"Cancel"}
      />
      <Dialog
        open={openReject.popup}
        onClose={() => setOpenReject({ popup: false, reason: null })}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center", padding: "15px 0 0 0" }}>
          Reject {selectedTaskPslIds.length} PSL(s)
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "450px",
            minWidth: "380px"
          }}
        >
          <div style={{ padding: "10px 0" }}>
            Do you want to reject selected PSL's? Please provide the reason of
            rejection:
          </div>
          <TextareaAutosize
            style={{ height: "200px", padding: "10px" }}
            onBlur={x => {
              setOpenReject({ ...openReject, reason: x.currentTarget.value });
            }}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => setOpenReject({ popup: false, reason: null })}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={onReject} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openReactivate}
        onClose={() => setOpenReactivate(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center", padding: "15px 0 0 0" }}>
          Reactivate PSL
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "450px",
            minWidth: "380px"
          }}
        >
          <div style={{ padding: "10px 0" }}>Please update the date:</div>
          <Grid container>
            <Grid item sm={4} style={{ padding: "5px 0" }}>
              Valid From:
            </Grid>
            <Grid
              item
              sm={8}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <CustomFastDate
                initialValue={psl && psl.validFrom}
                disabled={true}
                onChange={void 0}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={4} style={{ padding: "5px 0" }}>
              Valid To:
            </Grid>
            <Grid item sm={8} style={{ paddingTop: "8px" }}>
              <CustomFastDate
                initialValue={
                  psl &&
                    psl.validTo &&
                    psl.validTo > DateTime.local().toFormat("MM/dd/yyyy")
                    ? psl.validTo
                    : DateTime.local()
                }
                minDate={DateTime.local()}
                onChange={value =>
                  setPsl({ ...psl, validTo: value.toString() })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button onClick={() => setOpenReactivate(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={onReactivate} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openChildReactivate}
        onClose={() => setOpenChildReactivate(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center", padding: "15px 0 0 0" }}>
          Undelete PSL
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "450px",
            minWidth: "380px"
          }}
        >
          <div style={{ padding: "10px 0" }}>
            Do you want to Undelete this Child PSL which is once deleted before
            by the Market Localizer?
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => setOpenChildReactivate(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={onReactivate} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <StyledDrawer
        anchor="right"
        open={toggleFilter}
        onClose={toggleDrawer(false, setToggleFilter)}
      >
        <PslFilterContainer
          onClose={toggleDrawer(false, setToggleFilter)}
          userRole={userRole}
          mainTab={mainTab}
          isApproverInApproval={isApproverInApproval}
          taskTab={taskTab}
        />
      </StyledDrawer>
      {notificationMessage && <Notification type={NotificationType.success} message={notificationMessage} />}
    </>
  );
};
