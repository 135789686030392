import React from "react";
import { Tooltip } from "antd";
import DehazeIcon from '@material-ui/icons/Dehaze';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import { useHistory, useLocation } from "react-router";

export default function SpenderViewBtns() {
    const Location = useLocation();
    const history = useHistory();

    return (
        <div style={{display: 'inherit'}}>
            <Tooltip title="Consolidated View">
                <ViewQuiltIcon fontSize='large'
                    style={{ color: `${Location.pathname.toUpperCase() === '/pslCatalogs'.toUpperCase() ? '#f19002' : '#000000de'}`, cursor: 'pointer' }}
                    onClick={() => history.push("/pslCatalogs")}
                />
            </Tooltip>
            <Tooltip title="Tabs View">
                <DehazeIcon fontSize='large'
                    style={{ color: `${Location.pathname.toUpperCase() === '/pslCatalogs'.toUpperCase() ? '#000000de' : '#f19002'}`, cursor: 'pointer' }}
                    onClick={() => history.push("/psl")}
                />
            </Tooltip>
        </div>
    )
}