import { Action, DownloadCompletedAction, ErrorAction, PendingAction, SuccessAction } from "../domains/core/actions";
import { SuccessNotificationAction } from "../domains/core/notifications";
import { IConfigurableSettings, IUserTenderCalendarItem, UserFeedback } from "../domains/user/user";
import { DocFlowFilterTabs } from "../../docFlows/domains/documentFlow/documentFlow";

export enum UserActionTypes {
  FETCH_USER = "FETCH_USER",
  FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE = "FETCH_USER_FAILURE",

  FETCH_LITE_USER = "FETCH_LITE_USER",
  FETCH_LITE_USER_SUCCESS = "FETCH_LITE_USER_SUCCESS",

  FETCH_USER_APP_FILTERS = "FETCH_USER_APP_FILTERS",
  FETCH_USER_APP_FILTERS_SUCCESS = "FETCH_USER_APP_FILTERS_SUCCESS",

  FETCH_USER_PROFILE = "FETCH_USER_PROFILE",
  FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS",

  SAVE_CONTRACT_FILTERS = "SAVE_CONTRACT_FILTERS",
  SAVE_CONTRACT_FILTERS_SUCCESS = "SAVE_CONTRACT_FILTERS_SUCCESS",
  SAVE_CONTRACT_FILTERS_FAILURE = "SAVE_CONTRACT_FILTERS_FAILURE",

  SAVE_CONTRACT_CALENDAR_FILTERS = "SAVE_CONTRACT_CALENDAR_FILTERS",
  SAVE_CONTRACT_CALENDAR_FILTERS_SUCCESS = "SAVE_CONTRACT_CALENDAR_FILTERS_SUCCESS",
  SAVE_CONTRACT_CALENDAR_FILTERS_FAILURE = "SAVE_CONTRACT_CALENDAR_FILTERS_FAILURE",

  SAVE_DOCFLOW_FILTERS_SUCCESS = "SAVE_DOCFLOW_FILTERS_SUCCESS",
  SAVE_DOCFLOW_FILTERS_FAILURE = "SAVE_DOCFLOW_FILTERS_FAILURE",

  SAVE_INVOICES_FILTERS_SUCCESS = 'SAVE_INVOICES_FILTERS_SUCCESS',
  SAVE_INVOICES_FILTERS_FAILURE = 'SAVE_INVOICES_FILTERS_FAILURE',

  SAVE_PSL_FILTERS_SUCCESS = "SAVE_PSL_FILTERS_SUCCESS",
  SAVE_PSL_FILTERS_FAILURE = "SAVE_PSL_FILTERS_FAILURE",

  SAVE_MRP_FILTERS_SUCCESS = "SAVE_MRP_FILTERS_SUCCESS",
  SAVE_MRP_FILTERS_FAILURE = "SAVE_MRP_FILTERS_FAILURE",

  SAVE_CB_FILTERS_SUCCESS = "SAVE_CB_FILTERS_SUCCESS",
  SAVE_CB_FILTERS_FAILURE = "SAVE_CB_FILTERS_FAILURE",

  SAVE_BA_FILTERS = "SAVE_BA_FILTERS",
  SAVE_BA_FILTERS_SUCCESS = "SAVE_BA_FILTERS_SUCCESS",
  SAVE_BA_FILTERS_FAILURE = "SAVE_BA_FILTERS_FAILURE",

  SAVE_PA_FILTERS = "SAVE_PA_FILTERS",
  SAVE_PA_FILTERS_SUCCESS = "SAVE_PA_FILTERS_SUCCESS",
  SAVE_PA_FILTERS_FAILURE = "SAVE_PA_FILTERS_FAILURE",

  SAVE_PSL_CATALOGS_FILTERS_SUCCESS = "SAVE_PSL_CATALOGS_FILTERS_SUCCESS",
  SAVE_MRP_PO_LONG_TEXT_FILTERS_SUCCESS = "SAVE_MRP_PO_LONG_TEXT_FILTERS_SUCCESS",

  SAVE_NBS_FILTERS_SUCCESS = "SAVE_NBS_FILTERS_SUCCESS",
  SAVE_NBS_BLOCKED_PARKED_FILTERS_SUCCESS = "SAVE_NBS_BLOCKED_PARKED_FILTERS_SUCCESS",
  SAVE_NBS_TRACKER_FILTERS_SUCCESS = "SAVE_NBS_BLOCKED_PARKED_FILTERS_SUCCESS",
  SAVE_NBS_SAP_SCOPE_FILTERS_SUCCESS = "SAVE_NBS_SAP_SCOPE_FILTERS_SUCCESS",
  SAVE_NBS_TEMPLATE_FILTERS_SUCCESS = "SAVE_NBS_TEMPLATE_FILTERS_SUCCESS",

  GET_USER_INTERACTIONS_STATISTICS = "GET_USER_INTERACTIONS_STATISTICS",
  GET_USER_INTERACTIONS_STATISTICS_SUCCESS = "GET_USER_INTERACTIONS_STATISTICS_SUCCESS",
  GET_USER_INTERACTIONS_STATISTICS_FAILURE = "GET_USER_INTERACTIONS_STATISTICS_FAILURE",

  FETCH_PREFERRED_COMPANIES = "FETCH_PREFERRED_COMPANIES",
  FETCH_PREFERRED_COMPANIES_SUCCESS = "FETCH_PREFERRED_COMPANIES_SUCCESS",
  FETCH_PREFERRED_COMPANIES_FAILURE = "FETCH_PREFERRED_COMPANIES_FAILURE",

  FETCH_TENDER_CALENDAR_INFO = "FETCH_TENDER_CALENDAR_INFO",
  FETCH_TENDER_CALENDAR_INFO_SUCCESS = "FETCH_TENDER_CALENDAR_INFO_SUCCESS",
  FETCH_TENDER_CALENDAR_INFO_FAILURE = "FETCH_TENDER_CALENDAR_INFO_FAILURE",

  SAVE_PREFERRED_COMPANY = "SAVE_PREFERRED_COMPANY",
  SAVE_PREFERRED_COMPANY_SUCCESS = "SAVE_PREFERRED_COMPANY_SUCCESS",
  SAVE_PREFERRED_COMPANY_FAILURE = "SAVE_PREFERRED_COMPANY_FAILURE",

  SAVE_NEW_COMPANY_CODE = "SAVE_COMPANY_CODE",
  SAVE_NEW_COMPANY_CODE_SUCCESS = "SAVE_COMPANY_CODE_SUCCESS",
  SAVE_NEW_COMPANY_CODE_FAILURE = "SAVE_COMPANY_CODE_FAILURE",

  SAVE_PREFERRED_ZONE = "SAVE_PREFERRED_ZONE",

  VIEW_CHANGES = "VIEW_CHANGES",
  VIEW_CHANGES_SUCCESS = "VIEW_CHANGES_SUCCESS",

  SEND_FEEDBACK = "SEND_FEEDBACK",
  SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS",
  SEND_FEEDBACK_FAILURE = "SEND_FEEDBACK_FAILURE",

  REFUSED_FEEDBACK = "REFUSED_FEEDBACK",
  REFUSED_FEEDBACK_FAILURE = "REFUSED_FEEDBACK_FAILURE",
  REFUSED_FEEDBACK_SUCCESS = "REFUSED_FEEDBACK_SUCCESS",

  FEATURES_LOADED = "FEATURES_LOADED",
  TENDER_CALENDAR_SYNCHRONIZED = "TENDER_CALENDAR_SYNCHRONIZED",

  SAVE_PSL_VENDORS_COLUMNS_SUCCESS = "SAVE_PSL_VENDORS_COLUMNS_SUCCESS",
  SAVE_PSL_MYTASKS_COLUMNS_SUCCESS = "SAVE_PSL_MYTASKS_COLUMNS_SUCCESS",
  SAVE_PSL_MATERIALS_COLUMNS_SUCCESS = "SAVE_PSL_MATERIALS_COLUMNS_SUCCESS",

  SAVE_RC_DOCS_TAB_COLUMNS_SUCCESS = "SAVE_RC_DOCS_TAB_COLUMNS_SUCCESS",
  SAVE_RC_INVOICES_TAB_COLUMNS_SUCCESS = "SAVE_RC_INVOICES_TAB_COLUMNS_SUCCESS",

  SAVE_RC_DOCS_ITEMTAB_COLUMNS_SUCCESS = "SAVE_RC_DOCS_ITEMTAB_COLUMNS_SUCCESS",
  SAVE_RC_INVOICES_ITEMTAB_COLUMNS_SUCCESS = "SAVE_RC_INVOICES_ITEMTAB_COLUMNS_SUCCESS",

  SAVE_RC_DOCS_GRTAB_COLUMNS_SUCCESS = "SAVE_RC_DOCS_GRTAB_COLUMNS_SUCCESS",
  SAVE_RC_INVOICES_GRTAB_COLUMNS_SUCCESS = "SAVE_RC_INVOICES_GRTAB_COLUMNS_SUCCESS",

  SAVE_RC_DOCS_IRTAB_COLUMNS_SUCCESS = "SAVE_RC_DOCS_IRTAB_COLUMNS_SUCCESS",
  SAVE_RC_INVOICES_IRTAB_COLUMNS_SUCCESS = "SAVE_RC_INVOICES_IRTAB_COLUMNS_SUCCESS",

  SAVE_RC_DOCS_ACCOUNTTAB_COLUMNS_SUCCESS = "SAVE_RC_DOCS_ACCOUNTTAB_COLUMNS_SUCCESS",

  SAVE_NBS_EASY_REQUEST_TAB_COLUMNS_SUCCESS = "SAVE_NBS_EASY_REQUEST_TAB_COLUMNS_SUCCESS",
  SAVE_NBS_BLOCKED_PARKED_TAB_COLUMNS_SUCCESS = "SAVE_NBS_BLOCKED_PARKED_TAB_COLUMNS_SUCCESS",
  SAVE_NBS_TRACKER_TAB_COLUMNS_SUCCESS = "SAVE_NBS_TRACKER_TAB_COLUMNS_SUCCESS",
  SAVE_NBS_SAP_SCOPE_TAB_COLUMNS_SUCCESS = "SAVE_NBS_SAP_SCOPE_TAB_COLUMNS_SUCCESS",
  SAVE_PSL_CATALOGS_COLUMNS_SUCCESS = "SAVE_PSL_CATALOGS_COLUMNS_SUCCESS",

  SAVE_NBS_MAINTEANCE__COLUMNS_SUCCESS = "SAVE_NBS_MAINTEANCE__COLUMNS_SUCCESS",

}

export class FetchUser extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_USER;
}

export class FetchUserSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_USER_SUCCESS;

  constructor(public user) {
    super();
  }
}

export class FetchUserError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_USER_FAILURE;
}

export class FetchLiteUser extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_LITE_USER;
}

export class FetchLiteUserSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_LITE_USER_SUCCESS;

  constructor(public user) {
    super();
  }
}

export class FetchUserAppFilters extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_USER_APP_FILTERS;
}

export class FetchUserAppFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_USER_APP_FILTERS_SUCCESS;

  constructor(public filters) {
    super();
  }
}

export class FetchUserProfile extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_USER_PROFILE;

  constructor() {
    super();
  }
}

export class FetchUserProfileSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_USER_PROFILE_SUCCESS;

  constructor(public profile) {
    super();
  }
}

export class SaveContractFilters extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_CONTRACT_FILTERS;

  constructor(public filters) {
    super();
  }
}

export class SaveContractFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_CONTRACT_FILTERS_SUCCESS;

  constructor(public filters) {
    super();
  }
}

export class SaveContractFiltersError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_CONTRACT_FILTERS_FAILURE;
}

export class SaveContractCalendarFilters extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_CONTRACT_CALENDAR_FILTERS;

  constructor(public filters, public done?) {
    super();
  }
}

export class SaveContractCalendarFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_CONTRACT_CALENDAR_FILTERS_SUCCESS;

  constructor(public filters) {
    super();
  }
}

export class SaveContractCalendarFiltersError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_CONTRACT_CALENDAR_FILTERS_FAILURE;
}


export class SaveDocflowFiltersSuccess extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_DOCFLOW_FILTERS_SUCCESS;

  constructor(public filters, public docType: DocFlowFilterTabs) {
    super();
  }
}

export class SaveInvoicesFiltersError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_INVOICES_FILTERS_FAILURE;
}

export class SaveInvoicesFiltersSuccess extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_INVOICES_FILTERS_SUCCESS;

  constructor(public filters) {
    super();
  }
}

export class SaveDocflowFiltersError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_DOCFLOW_FILTERS_FAILURE;
}

export class SendFeedback extends Action<UserActionTypes> {
  public readonly type = UserActionTypes.SEND_FEEDBACK;

  constructor(public feedback: UserFeedback, public onDone) {
    super();
  }
}

export class SendFeedbackSuccess extends SuccessNotificationAction<UserActionTypes> {
  public readonly type = UserActionTypes.SEND_FEEDBACK_SUCCESS;

  constructor(public message: string) {
    super();
  }
}

export class SendFeedbackError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SEND_FEEDBACK_FAILURE;
}

export class SavePslFiltersSuccess extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PSL_FILTERS_SUCCESS;

  constructor(public filters) {
    super();
  }
}

export class SavePslFiltersError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PSL_FILTERS_FAILURE;
}

export class SaveMrpFiltersSuccess extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_MRP_FILTERS_SUCCESS;

  constructor(public filters) {
    super();
  }
}

export class SaveMrpFiltersError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_MRP_FILTERS_FAILURE;
}

export class SaveCBFiltersSuccess extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_CB_FILTERS_SUCCESS;

  constructor(public filters) {
    super();
  }
}

export class SaveCBFiltersError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_CB_FILTERS_FAILURE;
}

export class SaveBusinessApprovalFilters extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_BA_FILTERS;
  constructor(public filters) {
    super();
  }
}

export class SaveBusinessApprovalFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_BA_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}

export class SaveBusinessApprovalFiltersError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_BA_FILTERS_FAILURE;
}

export class SaveProcurementApprovalFilters extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PA_FILTERS;
  constructor(public filters) {
    super();
  }
}

export class SaveProcurementApprovalFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PA_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}
export class SavePslCatalogsFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PSL_CATALOGS_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}
export class SaveNbsFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NBS_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}

export class SaveMrpPoLongTextFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_MRP_PO_LONG_TEXT_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}

export class SaveNbsBlockedParkedFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NBS_BLOCKED_PARKED_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}
export class SaveNbsTrackerFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NBS_TRACKER_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}
export class SaveNbsSapScopeFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NBS_SAP_SCOPE_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}
export class SaveNbsTemplateFiltersSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NBS_TEMPLATE_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}
export class SavePslVendorsColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PSL_VENDORS_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SavePslMyTasksColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PSL_MYTASKS_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SavePslMaterialsColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PSL_MATERIALS_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SaveRcDocsTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_RC_DOCS_TAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SaveNbsEasyRequestTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NBS_EASY_REQUEST_TAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SaveNbsBlockedParkedTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NBS_BLOCKED_PARKED_TAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}

export class SaveNbsSapScopeTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NBS_SAP_SCOPE_TAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}

export class SaveNbsMainteanceColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NBS_MAINTEANCE__COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}

export class SaveNbsTrackerTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NBS_TRACKER_TAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}

export class SaveNbPslCatalogTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PSL_CATALOGS_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}

export class SaveRcInvoicesTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_RC_INVOICES_TAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SaveRcDocsItemTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_RC_DOCS_ITEMTAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SaveRcInvoicesItemTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_RC_INVOICES_ITEMTAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SaveRcDocsGrTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_RC_DOCS_GRTAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SaveRcDocsIrTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_RC_DOCS_IRTAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SaveRcInvoicesIrTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_RC_INVOICES_IRTAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SaveRcInvoicesGrTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_RC_INVOICES_GRTAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}
export class SaveRcDocsAccountTabColumnsSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_RC_DOCS_ACCOUNTTAB_COLUMNS_SUCCESS;
  constructor(public columns) {
    super();
  }
}

export class SaveProcurementApprovalFiltersError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PA_FILTERS_FAILURE;
}
export class GetUserInteractionStatistics extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.GET_USER_INTERACTIONS_STATISTICS;
}

export class GetUserInteractionStatisticsSuccess extends DownloadCompletedAction<UserActionTypes> {
  public readonly type = UserActionTypes.GET_USER_INTERACTIONS_STATISTICS_SUCCESS;
}

export class GetUserInteractionStatisticsError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.GET_USER_INTERACTIONS_STATISTICS_FAILURE;
}

export class FetchPreferredCompanies extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_PREFERRED_COMPANIES;
}
export class FetchPreferredCompaniesSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_PREFERRED_COMPANIES_SUCCESS;
  constructor(public companies) {
    super();
  }
}
export class FetchPreferredCompaniesError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_PREFERRED_COMPANIES_FAILURE;
}

export class FetchTenderCalendarInfo extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_TENDER_CALENDAR_INFO;
}

export class FetchTenderCalendarInfoSuccess extends SuccessAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_TENDER_CALENDAR_INFO_SUCCESS;

  constructor(public buyers: IUserTenderCalendarItem[],
    public clusters: IUserTenderCalendarItem[],
    public zones: IUserTenderCalendarItem[],
    public markets: IUserTenderCalendarItem[],
    public categories: IUserTenderCalendarItem[],
    public companyCodes: IUserTenderCalendarItem[],
    public businesses: IUserTenderCalendarItem[]) {
    super();
  }
}

export class FetchTenderCalendarInfoError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.FETCH_TENDER_CALENDAR_INFO_FAILURE;
}

export class SavePreferredCompany extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PREFERRED_COMPANY;

  constructor(public payload: IConfigurableSettings, public done) {
    super();
  }
}

export class SavePreferredCompanySuccess extends SuccessNotificationAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PREFERRED_COMPANY_SUCCESS;

  constructor(public payload: IConfigurableSettings) {
    super();
  }
}

export class SavePreferredCompanyError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PREFERRED_COMPANY_FAILURE;
}
export class SaveNewCompanyCode extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NEW_COMPANY_CODE;

  constructor(public payload: any, public done) {
    super();
  }
}

export class SaveNewCompanyCodeSuccess extends SuccessNotificationAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NEW_COMPANY_CODE_SUCCESS;
}

export class SaveNewCompanyCodeError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_NEW_COMPANY_CODE_FAILURE;
}

export class SavePreferredZone extends PendingAction<UserActionTypes> {
  public readonly type = UserActionTypes.SAVE_PREFERRED_ZONE;

  constructor(public zone: number) {
    super();
  }
}

export class ViewChanges extends Action<UserActionTypes> {
  public readonly type = UserActionTypes.VIEW_CHANGES;

  constructor(public changeIds: number[]) {
    super();
  }
}

export class ViewChangesSuccess extends Action<UserActionTypes> {
  public readonly type = UserActionTypes.VIEW_CHANGES_SUCCESS;
}

export class RefusedSendFeedback extends Action<UserActionTypes> {
  public readonly type = UserActionTypes.REFUSED_FEEDBACK;
}

export class RefusedSendFeedbackSuccess extends Action<UserActionTypes> {
  public readonly type = UserActionTypes.REFUSED_FEEDBACK_SUCCESS;
}

export class RefusedSendFeedbackError extends ErrorAction<UserActionTypes> {
  public readonly type = UserActionTypes.REFUSED_FEEDBACK_FAILURE;
}

export class FeaturesLoaded extends Action<UserActionTypes> {
  public readonly type = UserActionTypes.FEATURES_LOADED;

  constructor(public features) {
    super();
  }
}

export class TenderCalendarSynchronized extends Action<UserActionTypes> {
  public readonly type = UserActionTypes.TENDER_CALENDAR_SYNCHRONIZED;

  constructor(public settings) {
    super()
  }
}

export type UserActions =
  | FetchUser
  | FetchUserSuccess
  | FetchUserError
  | FetchLiteUser
  | FetchLiteUserSuccess
  | FetchUserAppFilters
  | FetchUserAppFiltersSuccess
  | FetchUserProfile
  | FetchUserProfileSuccess
  | SaveContractFilters
  | SaveContractFiltersSuccess
  | SaveContractFiltersError
  | SaveContractCalendarFilters
  | SaveContractCalendarFiltersSuccess
  | SaveContractCalendarFiltersError
  | SaveDocflowFiltersSuccess
  | SaveDocflowFiltersError
  | SaveInvoicesFiltersSuccess
  | SaveInvoicesFiltersError
  | SavePslFiltersSuccess
  | SavePslFiltersError
  | SaveMrpFiltersSuccess
  | SaveMrpFiltersError
  | SaveCBFiltersSuccess
  | SaveCBFiltersError
  | SaveBusinessApprovalFilters
  | SaveBusinessApprovalFiltersSuccess
  | SaveBusinessApprovalFiltersError
  | SaveProcurementApprovalFilters
  | SaveProcurementApprovalFiltersSuccess
  | SavePslCatalogsFiltersSuccess
  | SaveNbsFiltersSuccess
  | SaveMrpPoLongTextFiltersSuccess
  | SaveNbsBlockedParkedFiltersSuccess
  | SaveNbsTrackerFiltersSuccess
  | SaveNbsSapScopeFiltersSuccess
  | SaveProcurementApprovalFiltersError
  | GetUserInteractionStatistics
  | GetUserInteractionStatisticsSuccess
  | GetUserInteractionStatisticsError
  | FetchPreferredCompanies
  | FetchPreferredCompaniesSuccess
  | FetchPreferredCompaniesError
  | FetchTenderCalendarInfo
  | FetchTenderCalendarInfoSuccess
  | FetchTenderCalendarInfoError
  | SavePreferredCompany
  | SavePreferredCompanySuccess
  | SavePreferredCompanyError
  | SavePreferredZone
  | ViewChanges
  | ViewChangesSuccess
  | SendFeedback
  | SendFeedbackSuccess
  | SendFeedbackError
  | RefusedSendFeedback
  | RefusedSendFeedbackSuccess
  | RefusedSendFeedbackError
  | FeaturesLoaded
  | TenderCalendarSynchronized
  | SavePslVendorsColumnsSuccess
  | SavePslMyTasksColumnsSuccess
  | SavePslMaterialsColumnsSuccess
  | SaveRcDocsTabColumnsSuccess
  | SaveRcInvoicesTabColumnsSuccess
  | SaveRcDocsItemTabColumnsSuccess
  | SaveRcInvoicesItemTabColumnsSuccess
  | SaveRcDocsGrTabColumnsSuccess
  | SaveRcDocsIrTabColumnsSuccess
  | SaveRcInvoicesIrTabColumnsSuccess
  | SaveRcInvoicesGrTabColumnsSuccess
  | SaveRcDocsAccountTabColumnsSuccess
  | SaveNbsEasyRequestTabColumnsSuccess
  | SaveNbsBlockedParkedTabColumnsSuccess
  | SaveNbsSapScopeTabColumnsSuccess
  | SaveNbsTrackerTabColumnsSuccess
  | SaveNbsTemplateFiltersSuccess
  | SaveNbPslCatalogTabColumnsSuccess
  | SaveNbsMainteanceColumnsSuccess

