
export interface FromNetworkNbsDataDTO {
    Material: string;
    Plant: string;
    Material_Type: string;
    Material_Text: string;
    Po_Text: string;
    Language: string;
}

export class PoLongTextData {
    constructor(
        public readonly Material,
        public readonly Plant,
        public readonly Material_Type,
        public readonly Material_Text,
        public readonly Po_Text,
        public readonly Language
    ) {
    }

    static FromBackend(data: FromNetworkNbsDataDTO): PoLongTextData {
        return new PoLongTextData(
            data.Material,
            data.Plant,
            data.Material_Type,
            data.Material_Text,
            data.Po_Text,
            data.Language
        )
    }
}



