import React from "react";
import MaterialTable from "material-table";
import { Formatter } from "src/app/shared/components/amount-formatter";

interface TaxProps {
    itemAmount: any;
    taxAmount: any;
}

export const TaxCalculationsPopup = ({ itemAmount,taxAmount }: TaxProps) => {

    const loading = false;
    const totalTax:any = (Number(itemAmount.PrPrice)+Number(taxAmount.totalTax)).toString();
    return (
        // <div>
        //     <div style={{
        //         background: "#f19001",
        //         padding: "16px 24px",
        //         color: "#fff",
        //         fontSize: "16px",
        //         lineHeight: "22px",
        //         borderRadius: "2px 2px 0 0",
        //         width: "900px",
        //         boxShadow: "0 0 1px 1px #f19001",
        //     }}
        //     >
        //         <span>Tax Calculations</span>
        //     </div>
        //     <Spin spinning={loading} size="default" tip="Loading...">
        //         <Row style={{
        //             padding: "24px",
        //             fontSize: "14px",
        //             lineHeight: "1.5715",
        //             overflowX: "auto",
        //             overflowY: "auto",
        //             maxHeight: "calc(50vh - 54px)",
        //         }}>
        //            <MaterialTable
        //                 title="Tax Calculations"
        //                 columns={[
        //                     {   
        //                         title: '', 
        //                         field: 'zone' 
        //                     },
        //                     { 
        //                         title: 'Request Currency', 
        //                         field: 'rc' ,
        //                     },
        //                     { 
        //                         title: 'Converted Currency', 
        //                         field: 'cc', 
        //                     },
        //                     {
        //                         title: 'Total With Conditions',
        //                         field: 'tc',
        //                     },
        //                     {
        //                         title: 'Total With Tax',
        //                         field: 'tt',
        //                     },
        //                 ]}
        //                 data={[
        //                     { zone: 'MX',  rc: '10000 MXN', cc: '5000 USD', tc: '10000 MXN', tt: ''  },
        //                     { zone: 'MX',  rc: '10000 MXN', cc: '5000 USD', tc: '10000 MXN' , tt: '' },
        //                     { zone: 'US',  rc: '10000 MXN', cc: '5000 USD', tc: '10000 MXN', tt: '6000 USD'  },
        //                 ]}
        //                 detailPanel={[
        //                     {
        //                         icon: 'add_circle_outline',
        //                         openIcon: 'remove_circle_outline',
        //                         tooltip: 'Total',
        //                         render: rowData => {
        //                             return (
        //                                 <div
        //                                     style={{
        //                                         // fontSize: 20,
        //                                         textAlign: 'center',
        //                                         // color: 'white',
        //                                         // backgroundColor: '#FDD835',
        //                                     }}
        //                                 >
        //                                     <MaterialTable
        //                                         columns={[
        //                                             {   
        //                                                 title: '', 
        //                                                 field: 'line' ,
                                                        
        //                                             },
        //                                             { 
        //                                                 title: '', 
        //                                                 field: 'rc' ,
        //                                             },
        //                                             { 
        //                                                 title: '', 
        //                                                 field: 'cc', 
        //                                             },
        //                                             {
        //                                                 title: '',
        //                                                 field: 'tc',
        //                                             },
        //                                             {
        //                                                 title: '',
        //                                                 field: 'tt',
        //                                             },
        //                                         ]}
        //                                         data={[
        //                                             { line: 'Line 10',  rc: '3000 MXN', cc: '1500 USD', tc: '30000 MXN', tt: '2000 USD'  },
        //                                             { line: 'Line 20',  rc: '3000 MXN', cc: '1500 USD', tc: '30000 MXN', tt: '2000 USD'  },
        //                                             { line: 'Line 30',  rc: '4000 MXN', cc: '2000 USD', tc: '40000 MXN', tt: '2000 USD'  },
        //                                         ]}
        //                                         options={{
        //                                             search: false,
        //                                             paging: false,
        //                                             toolbar : false,
        //                                             header: false
        //                                         }}
        //                                     />
        //                                 </div>
        //                             )
        //                         },
        //                     },
        //                 ]}
        //                 options={{
        //                     search: false,
        //                     paging: false
        //                 }}
        //             />
        //         </Row>
        //     </Spin>
        // </div>

        <div>
            <div 
                style={{
                    // background: "white",
                    padding: "25px 30px 0px 30px",
                    // color: "#fff",
                    fontSize: "20px",
                    // lineHeight: "22px",
                    // borderRadius: "2px 2px 0 0",
                    width: "350px",
                    // boxShadow: "0 0 1px 1px #f19001",
                }}
            >
                <span>Tax Calculations</span>
            </div>
            <div style={{
                display:"flex", 
                gap:"30px",
                fontSize: "16px",
                // background: "white",
                padding: "25px 30px 25px 25px",
                // color: "#fff",
                // fontSize: "16px",
                // lineHeight: "22px",
                // borderRadius: "2px 2px 0 0",
                width: "350px",
                // boxShadow: "0 0 1px 1px #f19001",
            }}>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <span style={{padding: "10px"}}>Tax</span>
                    <span style={{padding: "10px"}}><Formatter amount={taxAmount.totalTax}/></span>
                </div>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <span style={{padding: "10px"}}>Total With Tax</span>
                    <span style={{padding: "10px"}}><Formatter amount={totalTax} /></span>
                </div>
            </div>
        </div>
    )
}