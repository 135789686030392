import {
  Action,
  AzureErrorAction,
  DownloadCompletedAction,
  ErrorAction,
  PendingAction,
  SuccessAction
} from "../../shared/domains/core/actions";
import { Mrp } from "../domains/mrp";
import { SuccessNotificationAction } from "../../shared/domains/core/notifications";
import { IMrpForm } from "../containers/mrpForm/mrpForm.utils";

export enum MRPTypes {

  FETCH_MRP_ENTITY = "FETCH_MRP_ENTITY",
  FETCH_MRP_ENTITY_REQUEST = "FETCH_MRP_ENTITY_REQUEST",
  FETCH_MRP_ENTITY_REQUEST_SUCCESS = "FETCH_MRP_ENTITY_REQUEST_SUCCESS",
  FETCH_MRP_ENTITY_REQUEST_FAILURE = "FETCH_MRP_ENTITY_REQUEST_FAILURE",

  SEARCH_MRP = "SEARCH_MRP",

  FETCH_SINGLE_MRP_ENTITY_AS_LIST = "FETCH_SINGLE_MRP_ENTITY_AS_LIST",
  FETCH_SINGLE_MRP_ENTITY = "FETCH_SINGLE_MRP_ENTITY",
  FETCH_SINGLE_MRP_ENTITY_SUCCESS = "FETCH_SINGLE_MRP_ENTITY_SUCCESS",
  FETCH_SINGLE_MRP_ENTITY_FAILURE = "FETCH_SINGLE_MRP_ENTITY_FAILURE",

  SAVE_MRP_ENTITY = "SAVE_MRP_ENTITY",
  SAVE_MRP_ENTITY_SUCCESS = "SAVE_MRP_ENTITY_SUCCESS",
  SAVE_MRP_ENTITY_FAILURE = "SAVE_MRP_ENTITY_FAILURE",

  UPDATE_MRP_ENTITY = "UPDATE_MRP_ENTITY",
  UPDATE_MRP_ENTITY_SUCCESS = "UPDATE_MRP_ENTITY_SUCCESS",
  UPDATE_MRP_ENTITY_FAILURE = "UPDATE_MRP_ENTITY_FAILURE",

  IMPORT_MRP_ENTITY = "IMPORT_MRP_ENTITY",
  IMPORT_MRP_ENTITY_SUCCESS = "IMPORT_MRP_ENTITY_SUCCESS",
  IMPORT_MRP_ENTITY_FAILURE = "IMPORT_MRP_ENTITY_FAILURE",
  RESET_MRP_ENTITY_IMPORT_ERROR = "RESET_MRP_ENTITY_IMPORT_ERROR",
  IMPORT_MRP_ENTITY_TIMEOUT = "IMPORT_MRP_ENTITY_TIMEOUT",

  EXPORT_MRP_ENTITY_TEMPLATE = "EXPORT_MRP_ENTITY_TEMPLATE",
  EXPORT_MRP_ENTITY_TEMPLATE_SUCCESS = "EXPORT_MRP_ENTITY_TEMPLATE_SUCCESS",
  EXPORT_MRP_ENTITY_TEMPLATE_FAILURE = "EXPORT_MRP_ENTITY_TEMPLATE_FAILURE",

  EXPORT_NBS_MAINTENANCE_ENTITY_TEMPLATE = "EXPORT_NBS_MAINTENANCE_ENTITY_TEMPLATE",
  EXPORT_NBS_MAINTENANCE_ENTITY_TEMPLATE_SUCCESS = "EXPORT_NBS_MAINTENANCE_ENTITY_TEMPLATE_SUCCESS",
  EXPORT_NBS_MAINTENANCE_ENTITY_TEMPLATE_FAILURE = "EXPORT_NBS_MAINTENANCE_ENTITY_TEMPLATE_FAILURE",

  EXPORT_MRP_ENTITY_LIST_DATA = "EXPORT_MRP_ENTITY_LIST_DATA",
  EXPORT_MRP_ENTITY_LIST_DATA_SUCCESS = "EXPORT_MRP_ENTITY_LIST_DATA_SUCCESS",

  EXPORT_MRP_PO_LONG_TEXT_DATA = "EXPORT_MRP_PO_LONG_TEXT_DATA",
  EXPORT_MRP_PO_LONG_TEXT_DATA_SUCCESS = "EXPORT_MRP_PO_LONG_TEXT_DATA_SUCCESS",
  EXPORT_MRP_PO_LONG_TEXT_DATA_FAILURE = "EXPORT_MRP_PO_LONG_TEXT_DATA_FAILURE",

  EXPORT_MRP_ENTITY_LIST_DATA_EMAIL = "EXPORT_MRP_ENTITY_LIST_DATA_EMAIL",
  EXPORT_MRP_ENTITY_LIST_DATA_EMAIL_SUCCESS = "EXPORT_MRP_ENTITY_LIST_DATA_EMAIL_SUCCESS",
  EXPORT_MRP_ENTITY_LIST_DATA_EMAIL_ERROR = "EXPORT_MRP_ENTITY_LIST_DATA_EMAIL_ERROR",
  RESET_MRP_ENTITY_EXPORT = "RESET_MRP_ENTITY_EXPORT",
  EXPORT_MRP_ENTITY_LIST_DATA_FAILURE = "EXPORT_MRP_ENTITY_LIST_DATA_FAILURE",

  MARK_AS_DELETED = "MARK_AS_DELETED",
  MARK_AS_DELETED_SUCCESS = "MARK_AS_DELETED_SUCCESS",
  MARK_AS_DELETED_FAILURE = "MARK_AS_DELETED_FAILURE",

  APPROVE_MRP_ITEM = "APPROVE_MRP_ITEM",
  APPROVE_MRP_ITEMS = "APPROVE_MRP_ITEMS",
  APPROVE_MRP_ITEM_SUCCESS = "APPROVE_MRP_ITEM_SUCCESS",
  APPROVE_MRP_ITEM_FAILURE = "APPROVE_MRP_ITEM_FAILURE",

  CHECK_MRP_ENTITY_FOR_DUPLICATES = "CHECK_MRP_ENTITY_FOR_DUPLICATES",
  CHECK_MRP_ENTITY_FOR_DUPLICATES_FAILURE = "CHECK_MRP_ENTITY_FOR_DUPLICATES_FAILURE",

  POST_MRP_SCRAPING = "POST_MRP_SCRAPING",
  POST_MRP_SCRAPING_SUCCESS = "POST_MRP_SCRAPING_SUCCESS",
  POST_MRP_SCRAPING_FAILURE = "POST_MRP_SCRAPING_FAILURE",

  GET_MRP_SCRAPING = "GET_MRP_SCRAPING",
  GET_MRP_SCRAPING_SUCCESS = "GET_MRP_SCRAPING_SUCCESS",
  GET_MRP_SCRAPING_FAILURE = "GET_MRP_SCRAPING_FAILURE",

  SEND_MRP_AS_CONTRACTS = "SEND_MRP_AS_CONTRACTS",
  SEND_MRP_AS_CONTRACTS_SUCCESS = "SEND_MRP_AS_CONTRACTS_SUCCESS",
  SEND_MRP_AS_CONTRACTS_FAILURE = "SEND_MRP_AS_CONTRACTS_FAILURE",

  EXPORT_PO_LONG_TEXT_SUCCESS_BY_EMAIL = "EXPORT_PO_LONG_TEXT_SUCCESS_BY_EMAIL"
}

export class FetchMRP extends PendingAction<MRPTypes> {
  public type = MRPTypes.FETCH_MRP_ENTITY;
  constructor() {
    super();
  }
}
export class SearchMRP extends PendingAction<MRPTypes> {
  public type = MRPTypes.SEARCH_MRP;
  constructor(public mrpSearch: { materialNumber: string, materialDescription: string, supplierNumber: string, supplierPartID: string, contractNumber: string }) {
    super();
  }
}
export class FetchMRPRequest extends PendingAction<MRPTypes> {
  public type = MRPTypes.FETCH_MRP_ENTITY_REQUEST;
}

export class FetchMRPRequestFailure extends AzureErrorAction<MRPTypes> {
  public type = MRPTypes.FETCH_MRP_ENTITY_REQUEST_FAILURE;
}

export class FetchMRPRequestSuccess extends SuccessAction<MRPTypes> {
  public type = MRPTypes.FETCH_MRP_ENTITY_REQUEST_SUCCESS;

  constructor(public payload: { data: Mrp[], total: number }) {
    super();
  }
}

export class SaveMRP extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.SAVE_MRP_ENTITY;

  constructor(public mrp: IMrpForm, public done, public doneWithErrors) {
    super();
  }
}

export class SaveMRPSuccess extends SuccessNotificationAction<MRPTypes> {
  public readonly type = MRPTypes.SAVE_MRP_ENTITY_SUCCESS;

  constructor(public competitiveBidding) {
    super();
  }
}

export class SaveMRPFailure extends AzureErrorAction<MRPTypes> {
  public readonly type = MRPTypes.SAVE_MRP_ENTITY_FAILURE;
}

export class UpdateMRP extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.UPDATE_MRP_ENTITY;
  constructor(public mrp: IMrpForm, public done, public doneWithErrors) {
    super();
  }
}
export class UpdateMRPSuccess extends SuccessNotificationAction<MRPTypes> {
  public readonly type = MRPTypes.UPDATE_MRP_ENTITY_SUCCESS;
}
export class UpdateMRPFailure extends AzureErrorAction<MRPTypes> {
  public readonly type = MRPTypes.UPDATE_MRP_ENTITY_FAILURE;
}

export class ImportMRP extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.IMPORT_MRP_ENTITY;

  constructor(public formData) {
    super();
  }
}

export class ImportMRPSuccess extends SuccessNotificationAction<MRPTypes> {
  public readonly type = MRPTypes.IMPORT_MRP_ENTITY_SUCCESS;

  constructor(public message) {
    super();
  }
}

export class ImportMRPError extends Action<MRPTypes> {
  public readonly type = MRPTypes.IMPORT_MRP_ENTITY_FAILURE;

  constructor(
    public file: string,
    public validationErrors: { key: number, value: { code: string, message: string }[] }[],
    public parsingErrors: { lineId: number, failedPropertyToParse: string, parsingExceptionMessage: string }[]
  ) {
    super();
  }
}

export class ImportMRPTimeout extends Action<MRPTypes> {
  public readonly type = MRPTypes.IMPORT_MRP_ENTITY_TIMEOUT;
}

export class ResetMRPImportError extends Action<MRPTypes> {
  public readonly type = MRPTypes.RESET_MRP_ENTITY_IMPORT_ERROR;
}

export class ExportMRPTemplate extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_ENTITY_TEMPLATE;
}

export class ExportMRPTemplateSuccess extends DownloadCompletedAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_ENTITY_TEMPLATE_SUCCESS;
}

export class ExportMRPTemplateError extends ErrorAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_ENTITY_TEMPLATE_FAILURE;
}

export class ExportNbsMaintenaceTemplate extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_NBS_MAINTENANCE_ENTITY_TEMPLATE;
}

export class ExportNbsMaintenaceTemplateSuccess extends DownloadCompletedAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_NBS_MAINTENANCE_ENTITY_TEMPLATE_SUCCESS;
}

export class ExportNbsMaintenaceTemplateError extends ErrorAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_NBS_MAINTENANCE_ENTITY_TEMPLATE_FAILURE;
}

export class ExportMRPListData extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA;
}

export class ExportMRPPoLongTextData extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_PO_LONG_TEXT_DATA;

  constructor(public payload) {
    super();
  }
}

export class ExportMRPListDataByEmail extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA_EMAIL;
}

export class ExportMRPListDataSuccess extends DownloadCompletedAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA_SUCCESS;
}

export class ExportMRPPoLongTextSuccess extends DownloadCompletedAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_PO_LONG_TEXT_DATA_SUCCESS;
}

export class ExportMRPListDataViaEmailSuccess extends SuccessNotificationAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA_EMAIL_SUCCESS;
  constructor(public message) {
    super();
  }
}
export class ExportMRPListDataViaEmailError extends ErrorAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA_EMAIL_ERROR;
}

export class ResetMRPExport extends Action<MRPTypes> {
  public readonly type = MRPTypes.RESET_MRP_ENTITY_EXPORT;
}

export class ExportMRPListDataError extends ErrorAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA_FAILURE;
}

export class ExportMRPPoLongTextError extends ErrorAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_MRP_PO_LONG_TEXT_DATA_FAILURE;
}

export class FetchSingleMrpAsList extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.FETCH_SINGLE_MRP_ENTITY_AS_LIST;
  constructor(public id: number) {
    super();
  }
}
export class FetchSingleMRP extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.FETCH_SINGLE_MRP_ENTITY;
  constructor(public id: number) {
    super();
  }
}

export class ExportPoLongTextDataEmailSuccess extends SuccessNotificationAction<MRPTypes> {
  public readonly type = MRPTypes.EXPORT_PO_LONG_TEXT_SUCCESS_BY_EMAIL;
  constructor(public message) {
    super();
  }
}

export class FetchSingleMRPSuccess extends SuccessAction<MRPTypes> {
  public readonly type = MRPTypes.FETCH_SINGLE_MRP_ENTITY_SUCCESS;
  constructor(public mrp: Mrp) {
    super();
  }
}
export class FetchSingleMRPFailure extends AzureErrorAction<MRPTypes> {
  public readonly type = MRPTypes.FETCH_SINGLE_MRP_ENTITY_FAILURE;
}

export class MarkAsDeleted extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.MARK_AS_DELETED;
  constructor(public mrpIds: number[], public comment: string, public done?: Function) { super(); }
}

export class MarkAsDeletedSuccess extends SuccessNotificationAction<MRPTypes> {
  public readonly type = MRPTypes.MARK_AS_DELETED_SUCCESS;
  constructor(public message) { super(); }
}

export class MarkAsDeletedError extends AzureErrorAction<MRPTypes> {
  public readonly type = MRPTypes.MARK_AS_DELETED_FAILURE;
}

export class CheckMrpForDuplicates extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.CHECK_MRP_ENTITY_FOR_DUPLICATES;
  constructor(public mrp: IMrpForm, public done) {
    super();
  }
}
export class CheckMrpForDuplicatesFailure extends AzureErrorAction<MRPTypes> {
  public readonly type = MRPTypes.CHECK_MRP_ENTITY_FOR_DUPLICATES_FAILURE;
}

export class PostMrpScraping extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.POST_MRP_SCRAPING;
  constructor() { super(); }
}
export class PostMrpScrapingSuccess extends SuccessNotificationAction<MRPTypes> {
  public readonly type = MRPTypes.POST_MRP_SCRAPING_SUCCESS;
  constructor(public message) { super(); }
}
export class PostMrpScrapingError extends AzureErrorAction<MRPTypes> {
  public readonly type = MRPTypes.POST_MRP_SCRAPING_FAILURE;
}

export class GetMrpScraping extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.GET_MRP_SCRAPING;
  constructor() { super(); }
}
export class GetMrpScrapingSuccess extends SuccessNotificationAction<MRPTypes> {
  public readonly type = MRPTypes.GET_MRP_SCRAPING_SUCCESS;
  constructor(public message) { super(); }
}
export class GetMrpScrapingError extends AzureErrorAction<MRPTypes> {
  public readonly type = MRPTypes.GET_MRP_SCRAPING_FAILURE;
}

export class SendMrpAsContracts extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.SEND_MRP_AS_CONTRACTS;
  constructor() { super(); }
}
export class SendMrpAsContractsSuccess extends SuccessNotificationAction<MRPTypes> {
  public readonly type = MRPTypes.SEND_MRP_AS_CONTRACTS_SUCCESS;
  constructor(public message) { super(); }
}
export class SendMrpAsContractsError extends AzureErrorAction<MRPTypes> {
  public readonly type = MRPTypes.SEND_MRP_AS_CONTRACTS_FAILURE;
}
export class ApproveMrpItem extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.APPROVE_MRP_ITEM;
  constructor(public mrpIds: number[], public done?: Function) {
    super();
  }
}
export class ApproveMrps extends PendingAction<MRPTypes> {
  public readonly type = MRPTypes.APPROVE_MRP_ITEMS;
  constructor(public mrpIds: number[], public done?: Function) {
    super();
  }
}
export class ApproveMrpSuccess extends SuccessNotificationAction<MRPTypes> {
  public readonly type = MRPTypes.APPROVE_MRP_ITEM_SUCCESS;
}
export class ApproveMrpFailure extends AzureErrorAction<MRPTypes> {
  public readonly type = MRPTypes.APPROVE_MRP_ITEM_FAILURE;
}
