export const vertexSampleResponse = {
    "data": {
        "documentNumber": "101276077",
        "lineItems": [
            {
                "costCenter": "500416101",
                "extendedPrice": 2200.0,
                "generalLedgerAccount": "5004120",
                "lineItemNumber": "00010",
                "totalTax": 410.5

            },
            {
                "costCenter": "500416101",
                "extendedPrice": 2200.0,
                "generalLedgerAccount": "5004120",
                "lineItemNumber": "00020",
                "totalTax": 102.5
            }
        ],
        "subTotal": 4400.0,
        "totalTax": 513,
        "total": 4913
    }
}