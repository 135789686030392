import {IUserRole} from "./user";
import {PSLColumns, PslVendorColumnsList} from "./pslColumns";
import {arraysConcat} from "../../utils/arraysHelper";

export function GetDefaultMyTasksColumns(userRole: IUserRole, isLocalizedList?: boolean) {
  switch (userRole) {
    case IUserRole.AT_MARKET:
      return PslAsAtMarket(isLocalizedList);
    case IUserRole.ABOVE_MARKET:
    case IUserRole.PPA_ABOVE_MARKET:
      return AboveMarketColumns(userRole, isLocalizedList);
    case IUserRole.OPERATIONAL_BUYER:
    default:
      return PslAsApprover(userRole, isLocalizedList);
  }
}

export function GetMyTasksColumns(userRole: IUserRole, columns: PslVendorColumnsList[], isLocalizedList?: boolean): PslVendorColumnsList[] {
  return arraysConcat(columns, GetDefaultMyTasksColumns(userRole, isLocalizedList));
}

function AboveMarketColumns(userRole, isLocalizedList) {
  return [...PslAsApprover(userRole, isLocalizedList), {id: PSLColumns.LOCATION, show: false, hidden: false}]
}


function PslAsAtMarket(isLocalizedList: boolean) {
  return [
    {id: PSLColumns.PSL_ID, show: true, hidden: false},
    {id: PSLColumns.PARENT_VENDOR, show: true, hidden: isLocalizedList ? false : true},
    {id: PSLColumns.CHILD_VENDOR, show: true, hidden: false},
    {id: PSLColumns.SUPPLIER_CONTACT, show: false, hidden: false},
    {id: PSLColumns.SUPPLIER_STATUS, show: true, hidden: false},
    {id: PSLColumns.VALID_FROM, show: false, hidden: false},
    {id: PSLColumns.VALID_TO, show: false, hidden: false},
    // {id: PSLColumns.LOCATION, show: false, hidden: false},
    {id: PSLColumns.ZONE, show: false, hidden: false},
    {id: PSLColumns.MARKET, show: false, hidden: false},
    {id: PSLColumns.COMPANY_CODE, show: true, hidden: false},
    {id: PSLColumns.AGREEMENTS, show: false, hidden: false},
    {id: PSLColumns.BUYER_NAME, show: true || false, hidden: false},
    {id: PSLColumns.TEAM_NAME, show: false, hidden: false},
    {id: PSLColumns.NOTE_REQUESTER, show: false, hidden: false},
    {id: PSLColumns.NOTE_LOCAL_PROCUREMENT, show: false, hidden: false},
    {id: PSLColumns.TENDER_NUMBER, show: false, hidden: false},
    {id: PSLColumns.BUSINESS_UNIT, show: true, hidden: false},
    {id: PSLColumns.PRICE_LIST_LINK, show: false, hidden: false},
    {id: PSLColumns.PRICE_AVAILABLE, show: true, hidden: false},
    {id: PSLColumns.CREATED_BY_USER, show: false, hidden: false},
    {id: PSLColumns.CREATED_ON, show: false, hidden: false},
    {id: PSLColumns.UPDATED_BY_USER, show: false, hidden: false},
    {id: PSLColumns.UPDATED_ON, show: false, hidden: false},
    {id: PSLColumns.RELATED_TO_EASYBUY, show: false, hidden: false},
    {id: PSLColumns.STATUS_JUSTIFICATION, show: false, hidden: false},
    {id: PSLColumns.STATUS_ATTACHMENTS, show: false, hidden: false},
    {id: PSLColumns.REJECTION, show: true, hidden: true},
    {id: PSLColumns.Market_Contact_Name, show: false, hidden: false},
    {id: PSLColumns.Localizer, show: false, hidden: false},
  ];
}

function PslAsApprover(userRole: IUserRole, isLocalizedList?: boolean) {
  let isChildVendor =  (IUserRole.ABOVE_MARKET === userRole || IUserRole.PPA_ABOVE_MARKET === userRole) ? true : false;
  let isParentVendor = IUserRole.PPA_AT_MARKET === userRole ? true : false;

  return [
    {id: PSLColumns.PSL_ID, show: true, hidden: false},
    {id: PSLColumns.PARENT_VENDOR, show: true, hidden: isLocalizedList ? false : isParentVendor},
    {id: PSLColumns.CHILD_VENDOR, show: true, hidden: isLocalizedList ? false: isChildVendor},
    {id: PSLColumns.SUPPLIER_CONTACT, show: false, hidden: false},
    {id: PSLColumns.SUPPLIER_STATUS, show: true, hidden: false},
    {id: PSLColumns.STATUS_JUSTIFICATION, show: true, hidden: false},
    // {id: PSLColumns.LOCATION, show: false, hidden: false},
    {id: PSLColumns.ZONE, show: false, hidden: false},
    {id: PSLColumns.MARKET, show: true, hidden: false},
    {id: PSLColumns.COMPANY_CODE, show: true, hidden: false},
    {id: PSLColumns.BUSINESS_UNIT, show: true, hidden: false},
    {id: PSLColumns.AGREEMENTS, show: true, hidden: false},
    {id: PSLColumns.PRICE_LIST_LINK, show: true, hidden: false},
    {id: PSLColumns.TENDER_NUMBER, show: true, hidden: false},
    {id: PSLColumns.VALID_FROM, show: true, hidden: false},
    {id: PSLColumns.VALID_TO, show: true, hidden: false},
    {id: PSLColumns.NOTE_LOCAL_PROCUREMENT, show: true, hidden: false},
    {id: PSLColumns.BUYER_NAME, show: true, hidden: false},

    {id: PSLColumns.TEAM_NAME, show: false, hidden: false},
    {id: PSLColumns.NOTE_REQUESTER, show: false, hidden: false},
    {id: PSLColumns.PRICE_AVAILABLE, show: false, hidden: false},
    {id: PSLColumns.CREATED_BY_USER, show: false, hidden: false},
    {id: PSLColumns.CREATED_ON, show: false, hidden: false},
    {id: PSLColumns.UPDATED_BY_USER, show: false, hidden: false},
    {id: PSLColumns.UPDATED_ON, show: false, hidden: false},
    {id: PSLColumns.RELATED_TO_EASYBUY, show: false, hidden: false},
    {id: PSLColumns.STATUS_ATTACHMENTS, show: false, hidden: false},
    {id: PSLColumns.REJECTION, show: false, hidden: false},
    {id: PSLColumns.Market_Contact_Name, show: false, hidden: false},
    {id: PSLColumns.Localizer, show: false, hidden: false},
  ];
}
