import styled from 'styled-components';
import Paper from "@material-ui/core/Paper/Paper";
import InputBase from "@material-ui/core/InputBase/InputBase";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {HorizontalView} from "../layout/styles";

export const SearchFilterWrapper = styled(HorizontalView)`
  flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

export const StyledPaper = styled(Paper)`
   padding: 2px 4px;
   display: flex;
   align-items: center;
   align-self: flex-start;
   width: 300px;
   height: 36px;
   border: 1px solid #C8D6DF;
   @media only screen and (max-width: 768px) {
    width: 250px;
   }
`;

export const NewStyledPaper = styled(Paper)`
   padding: 2px 4px;
   display: flex;
   align-items: center;
   align-self: flex-start;
   width: 260px;
   height: 38px;
   font-size: 14px;
   border: 1px solid #C8D6DF;
   @media only screen and (max-width: 768px) {
    width: 260px;
   }
`;

export const StyledInput = styled(InputBase)`
   margin-left: 8px;
   flex: 1;
`;

export const InlineStyledPaper = styled(StyledPaper)`
  display: inline;
  padding: 5px;
  margin: 10px;
`;

export const StyledIconButton = styled(IconButton)`
   padding: 10px;
`;
