import {assoc} from "ramda";
import {UI_MODULE} from "../domains/core/ui";
import {LOCATION_CHANGE, LocationChangeAction} from "connected-react-router";

export interface UIState {
  module: UI_MODULE;
}

const initialUIState: UIState = {
  module: UI_MODULE.CONTRACT
};

function updateModuleByPathname(state, pathname) {
  switch(pathname.toLowerCase()){
    case '/':
      return ['local', 'dev'].includes(process.env.REACT_APP_ENV) ? assoc('module', UI_MODULE.DASHBOARD, state) : assoc('module', UI_MODULE.CONTRACT, state);
    case '/contracts':
      return assoc('module', UI_MODULE.CONTRACT, state);
    case '/contract/appointments':
      return assoc('module', UI_MODULE.TENDER_CALENDAR, state);
    case '/bas':
    case '/pas':
    case '/business-approval':
    case '/purchasing-approval':
    case '/purchase-request':
      return assoc('module', UI_MODULE.APPROVAL, state);
    case '/nbs-cockpit/ob-validation':
      return assoc('module', UI_MODULE.NBS_COCKPIT, state);
  }

  if(pathname.match(/^\/approval-status(?:\/[^\/]*)?$/) ||
    pathname.match(/^\/pr-substitution(?:\/[^\/]*)?$/)) {
    return assoc('module', UI_MODULE.APPROVAL, state);
  }

  if(pathname.match(/^\/contract(?:\/[^\/]*)?$/)) {
    return assoc('module', UI_MODULE.CONTRACT, state);
  }

  if(pathname.match(/^\/document-flow-old(?:\/[^\/]*)?$/)) {
    return assoc('module', UI_MODULE.DOCFLOW, state);
  }

  if(pathname.match(/^\/document-flow(?:\/[^\/]*)?$/)) {
    return assoc('module', UI_MODULE.DOCFLOW, state);
  }

  if(pathname.match(/^\/competitive-bidding(?:\/[^\/]*)?$/)) {
    return assoc('module', UI_MODULE.CB, state);
  }

  if(pathname.startsWith('/psl')) {
    return assoc('module', UI_MODULE.PSL, state);
  }

  if(pathname.match(/^\/mrp(?:\/[^\/]*)?$/)) {
    return assoc('module', UI_MODULE.MRP, state);
  }

  return state;
}

export function UIReducer(state: UIState = initialUIState, action: LocationChangeAction): UIState {
  switch (action.type) {
    case LOCATION_CHANGE:
      return updateModuleByPathname(state, action.payload.location.pathname);
    default:
      return state;
  }
}
