import React from "react";
import { DataWithPopover } from "src/app/nbsCockpit/components/nbsCockpitDataWithPopover";
import { Formatter } from "src/app/shared/components/amount-formatter";
import { InfoHoverIcon } from "../components/itemDrilldownTable/editRequestAllLines/components/InfoHoverIcon";
import { TaxCalculationsPopup } from "../components/itemDrilldownTable/editRequestAllLines/components/TaxCalculationsPopup";
import _ from "lodash";

export function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

export const hoverDetails = (item: any, hoverItem: any) => {
    return (
        <DataWithPopover
            item={item}
            hoverItem={hoverItem}
        />
    )
}

export const verifyAndGetVertexPayload = (itemsData: any, itemData: any, prData:any) => {
    let data = {status: false, payload: {}};
    const isVertexCallRealtedDataExist = itemsData.filter((item=>(item.Potaxcode === 'I1' || item.Taxcode === 'I1')));
    if(prData.POCompanyCode.substring(0, 2).toLocaleUpperCase() === 'US'){
        if(isVertexCallRealtedDataExist.length > 0){
            const date = new Date();
            const todayDate = `${date.getFullYear().toString()+date.getMonth().toString()+date.getDate().toString()}`;
            const getflexibleCodeFields  = (item) => {
                console.log(item.PR_AccCategory)
                if(item.PR_AccCategory === 'K')
                    return {
                        flexibleCodeFields : [
                            {
                                fieldId: "11", 
                                value: item?.VerCCCat ? item.VerCCCat.charAt(0) : ""
                            }
                        ]
                    }
                else if(item.PR_AccCategory === "")
                    return {
                        flexibleCodeFields : [
                            {
                                fieldId: "12", 
                                value: item?.MaterialType ? item.MaterialType : ""
                            }
                        ]
                    }
                else
                    return {
                        flexibleCodeFields : [
                            {
                                fieldId: "13", 
                                value:  item?.VerCostObject ? item.VerCostObject : ""
                            }
                        ]
                    }
            }
            const lineItems = isVertexCallRealtedDataExist.map((item)=>{
                return {
                    lineItemNumber: item.PrItem,
                    taxDate: todayDate,
                    costCenter: item.Pr_CostCenter,
                    generalLedgerAccount: item.Pr_GlAccount,
                    projectNumber: " ",
                    extendedPrice: item.PrPrice,
                    purchase: {
                        value: item?.MatTaxCode ? item.MatTaxCode : ""
                    },
                    quantity: {
                        unitOfMeasure: item?.PrQuantityUom !== "" ? item?.PrQuantityUom : prData.Currency,
                        value: item.PrQuantity
                    },
                    flexibleFields: getflexibleCodeFields(item)
                }
            });
            const payload = {
                purchaseMessageType: "PURCHASE_ORDER",
                documentNumber: itemData.PrNo,
                orderType: "EasyReq",
                locationCode: "5001",
                documentDate: todayDate,
                transactionType: "PURCHASE",
                deliveryTerm: "SUP",
                buyer:{
                    company: "Nestle",
                    division: itemData?.CompCode !== "" ? itemData?.CompCode : prData.POCompanyCode,
                    destination:{
                        streetAddress1: itemData.Name1,
                        city: itemData.City,
                        mainDivision: itemData.Region,
                        postalCode: itemData.Postalcode,
                        country: itemData.Country
                    }
                },
                vendor:{
                    vendorCode:{
                        value: itemData.Supplier
                    },
                    administrativeOrigin:{
                        streetAddress1: itemData?.VendStreet ? itemData.VendStreet : "",
                        city: itemData?.VendCity ? itemData.VendCity : "",
                        mainDivision: itemData?.VendRegion ? itemData.VendRegion : "",
                        postalCode: itemData?.VendPostCode ? itemData.VendPostCode : "",
                        country: itemData?.VendCountry ? itemData.VendCountry : ""
                    },
                    physicalOrigin:{
                        streetAddress1: itemData.Name1,
                        city: itemData.City,
                        mainDivision: itemData.Region,
                        postalCode: itemData.Postalcode,
                        country: itemData.Country
                    }
                },
                lineItems:lineItems
            }
            return {status: true, payload: payload}
        }
        return data
    }
    return data;
}

export const showAmountOrAmountWithIcon = (item,data) => {
    if(!data.loading && !_.isEmpty(data.data)){
        const newData = data?.data?.data;
        const itemExist = newData?.lineItems?.filter((record)=>item.PrItem == record.lineItemNumber);
        if(itemExist.length>0 && itemExist.length===1){
            return <div style={{display:"flex", gap:"3px"}}><Formatter amount={item.PrPrice} /><InfoHoverIcon popUpComponent={<TaxCalculationsPopup itemAmount={item} taxAmount={itemExist[0]} />} /></div> 
        }else{
            return <Formatter amount={item.PrPrice} />
        }
    }else{
        return <Formatter amount={item.PrPrice} />
    }
}



