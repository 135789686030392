import React from "react";
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from "@material-ui/core";
import { useStylesForToolTip } from "src/app/shared/components/tooltip/toolTip.styles";

export const InfoHoverIcon = ({ popUpComponent, marginLeft="3px", marginBottom="-5px", color="orange" }) => {

    const toolTipclass = useStylesForToolTip({});

    return (
        <Tooltip
            interactive
            classes={{ tooltip: toolTipclass.customTooltipForVendorHover }}
            title={ 
                popUpComponent
            }
            placement="right-end"
        >
            <InfoIcon
                style={{ color: color, cursor: "pointer", marginLeft: marginLeft, marginBottom: marginBottom }}
                fontSize="small"
            />
        </Tooltip>
    )
}