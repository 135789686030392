
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { ApplyButton } from 'src/app/feedbackCockpit/components/styles';
import { StyledConfirmDialog } from 'src/app/shared/containers/dashboard/components/feedback/feedback.styles';
import { StyledDialogTitle, StyledDrawer, Toolbar } from 'src/app/shared/components/layout/styles';
import { createStyles, DialogActions, Drawer, makeStyles, Portal, Theme } from '@material-ui/core';
import Notification, { NotificationType } from "src/app/shared/components/notification";
import { SendInstructionsTable } from './nbsSendInstructionTable';
import { useFetchNbsMaintenanceTemplatesData } from '../hooks/nbsCockpitCustomHooks';
import { Invoice } from 'src/app/docFlows/invoices/domains/invoices/Invoice';
import { FilterButton, SecondaryButton } from 'src/app/shared/components/button';
import { getInitialFiltersForNbsMaintenance, logUserIntraction } from '../helpers/functions';
import { RootStateStore } from 'src/app/application.reducers';
import { useSelector } from 'react-redux';
import { tempBlockedParkedToActorOnOptions } from '../helpers/constants';
import NbsInstructionsFilters, { NbsInstructionsActiveFilters } from './nbsInstructionsFilters';
import { FeaturesContext } from 'src/app/shared/domains/core/pataflag.context';
import { Features } from 'src/app/shared/domains/core/pataflag';
import { UserInteractionType } from 'src/app/shared/domains/user/userInteractionType';

const drawerWidth = 240;

const useStyles = makeStyles(() =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        }
    }),
);

type sendInstructionPopUpProps = {
    invoice: Invoice;
    openInstructionPopUp: boolean;
    setOpenInstructionPopUp: React.Dispatch<React.SetStateAction<boolean>>;
    handleSendInstruction: (instructionsData: string) => void;
    closeMenu: React.Dispatch<any>;
    zone: string;
};

export const NbsSendInstructionPopUpComponent = (props: sendInstructionPopUpProps) => {
    const { invoice, openInstructionPopUp, setOpenInstructionPopUp, closeMenu, zone } = props;
    const [rows, setRows] = React.useState<number>(5);
    const [page, setPage] = React.useState<number>(0);
    const [showNbsInstructionsFilters, toggleNbsInstructionsFilters] = React.useState<boolean>(false);
    const dateFormat = useSelector((state: RootStateStore) => state.shared.user.currentUser.profileSettings.dateTimeFormat);
    const classes = useStyles({});
    const { hasFeature } = React.useContext(FeaturesContext);

    const filters = {
        compCode: [{ id: invoice?.CompCode, title: "" }],
        purGroup: [{ id: invoice?.PurchGrp, title: "" }],
        plant: [{ id: invoice?.Plant, title: "" }],
        // MaterialType: [{ id: invoice?.CompCode, title: invoice?.CompCode }],
        parkingBlockingReason: [{ id: invoice?.BlckRsn || invoice?.ParkRsnCode, title: "" }],
    };

    const [nbsInstructionsGetQueryObject, setNbsInstructionsGetQueryObject] = React.useState({
        filters: getInitialFiltersForNbsMaintenance(filters),
        page: page,
        rows: rows,
    });

    const { data: instructionData,
        isLoading: isNbsTableDataLoading,
        isError: isNbsTableDataError,
        error: nbsTableDataError } = useFetchNbsMaintenanceTemplatesData(nbsInstructionsGetQueryObject);

    const [selectedInstructions, setSelectedInstructions] = React.useState<any>([]);

    function toggleNbsInstructionsFiltersDrawer(open: boolean, toggle: (boolean) => void): (event: React.KeyboardEvent | React.MouseEvent) => any {
        return (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
                return;
            }
            toggle(open);
        }
    }

    function handleClose() {
        setOpenInstructionPopUp(false);
        closeMenu(null);

    };

    const handlePageChange = (page: number) => {
        setPage(page);
        setNbsInstructionsGetQueryObject(prevState => ({
            ...prevState,
            page: page
        }));
    }

    const handleRowsPerPageChange = (page: number, rows: number) => {
        setRows(rows);
        setNbsInstructionsGetQueryObject(prevState => ({
            ...prevState,
            rows: rows,
            page: 0
        }));
    }

    async function updateNbsInstructionsFilters(filters: any) {
        setNbsInstructionsGetQueryObject(prevState => ({
            ...prevState,
            filters: filters,
            page: 0,
            rows: 10
        }));
        toggleNbsInstructionsFilters(false);
    }

    const handleGenerateInstruction = () => {
        const tempData = selectedInstructions.map(item => {
            delete item["tableData"];
            return item
        });
        const instructionData = tempData?.map(el => el?.instructions)?.join("\n\n") + " ";
        props.handleSendInstruction(instructionData);
        logUserIntraction(UserInteractionType.SendNBSInstructions);
        handleClose();
        closeMenu(null);
    };

    return <StyledConfirmDialog open={openInstructionPopUp} maxWidth={"lg"} scroll={'paper'} fullWidth={true}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ backgroundColor: "#F19001" }}>
                <StyledDialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            <span style={{ color: 'white' }}>Choose A template</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
                            <CloseIcon onClick={handleClose} style={{ color: 'white' }}>close</CloseIcon>
                        </div>
                    </div>
                </StyledDialogTitle>
            </div>
            <div style={{
                position: "relative", zIndex: 9999, overflow: "hidden"
            }}>
                {hasFeature(Features.NBS_167630_NEW_CHAT_CONTACTS) && <Toolbar style={{ backgroundColor: "white", minHeight: 80, marginRight: "20px", flexDirection: "row", alignItems: "center" }}>
                    <NbsInstructionsActiveFilters
                        filters={nbsInstructionsGetQueryObject?.filters}
                        setQueryObject={updateNbsInstructionsFilters}
                    />
                    <FilterButton onClick={toggleNbsInstructionsFiltersDrawer(true, toggleNbsInstructionsFilters)} />
                </Toolbar>}
                <div>
                    <SendInstructionsTable
                        totalCount={instructionData?.count || 0}
                        currentPage={page}
                        onNewPage={handlePageChange}
                        rowsPerPage={rows}
                        setRowsPerPage={handleRowsPerPageChange}
                        data={instructionData?.data ? instructionData?.data : []}
                        setSelectedInstructions={setSelectedInstructions}
                        loading={isNbsTableDataLoading}
                    />
                </div>
                {/* <Drawer
                    PaperProps={{
                        style: {
                            position: "absolute"
                        }
                    }}
                    variant="persistent"
                    anchor="right"
                    open={showNbsInstructionsFilters}
                    onClose={toggleNbsInstructionsFiltersDrawer(false, toggleNbsInstructionsFilters)}
                > */}
                <StyledDrawer anchor="right" open={showNbsInstructionsFilters} onClose={toggleNbsInstructionsFiltersDrawer(false, toggleNbsInstructionsFilters)}>
                    <NbsInstructionsFilters
                        zone={zone}
                        onClose={toggleNbsInstructionsFiltersDrawer(false, toggleNbsInstructionsFilters)}
                        dateFormat={dateFormat}
                        updateFilters={updateNbsInstructionsFilters}
                        queryData={nbsInstructionsGetQueryObject}
                        receivers={tempBlockedParkedToActorOnOptions}
                    />
                    {/* </Drawer> */}
                </StyledDrawer>
                <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "10px", margin: "0 20px 20px 10px" }}>
                    <SecondaryButton buttonlabel={"CANCEL"} onClick={handleClose} />
                    <ApplyButton
                        onClick={handleGenerateInstruction}
                        disabled={selectedInstructions.length === 0}
                        variant="contained" color="primary">
                        GENERATE INSTRUCTIONS
                    </ApplyButton>
                </DialogActions>
            </div>
        </div>
        {isNbsTableDataError && <Portal ><Notification type={NotificationType.error} message={nbsTableDataError.toString()} /></Portal>}
    </StyledConfirmDialog >
};