import * as R from "ramda";
import { DateTime } from "luxon";
import { CreationDateFilter, DateRangeFilter, SimpleTextFilter, TextFilter } from "../query/filter";
import { SAPBoolean, SAPBooleanToBoolean } from "../core/sapBoolean";
import { Features } from "../core/pataflag";
import { PataflagService } from "../../services/pataflag.service";

interface ILoadState {
  loading?: boolean;
  loaded?: boolean;
}

export enum IUserRole {
  AT_MARKET = 0,
  ABOVE_MARKET = 1,
  REQUESTOR = 2,
  FACTORY_ANALYST = 3,
  OPERATIONAL_BUYER = 4,
  PPA_AT_MARKET = 5,
  PPA_ABOVE_MARKET = 6,
  VIEW_ONLY = 7
}

export type Country = { name: string; id: number };

export interface IUserTenderCalendarItem {
  id?: number;
  name: string;
  code: string;
}

export interface IContractFilters extends ILoadState {
  companies: TextFilter[];
  pOrgs: TextFilter[];
  pGroups: TextFilter[];
  docTypes: TextFilter[];
  mGroups: TextFilter[];
  materials: TextFilter[];
  plants: TextFilter[];
  strategicBuyers: TextFilter[];
  validity?: string;
  expiryRange?: string[];
  validityEnd?: string;
}

export interface IBAFilters {
  companies: TextFilter[];
  purchaseReqNo: TextFilter[],
  prCreatorName: TextFilter[],
  supplier: TextFilter[],
  purchGroup: TextFilter[],
  wbsElement: TextFilter[],
  costCenter: TextFilter[],
  validity?: string;
  historic?: boolean;
}

export interface IAFilters {
  pslCreationAndValidityDate: CreationDateFilter;
  markets: TextFilter[];
  companies: TextFilter[];
  spendCatL1s: TextFilter[];
  spendCatL2s: TextFilter[];
  spendCatL3s: TextFilter[];
  strategicBuyers: TextFilter[];
}

export interface IPAFilters {
  companies: TextFilter[];
  purchaseReqNo: TextFilter[],
  prCreatorName: TextFilter[],
  supplier: TextFilter[],
  purchGroup: TextFilter[],
  validity?: string;
}

export interface IContractCalendarFilters {
  buyers: TextFilter[];
  spendCategories: TextFilter[];
  clusters: TextFilter[];
  zones: TextFilter[];
  markets: TextFilter[];
  companyCodes: TextFilter[];
  plants: TextFilter[];
  businesses: TextFilter[];
  negotiationType: TextFilter[];
}

export interface IPrismFilter {
  buyerId: number,
  spendCategories: string[],
  clusters: string[]
}

export interface IInvoiceFilters {
  companies: TextFilter[];
  pOrgs: TextFilter[];
  supplier: TextFilter[];
  invoiceNumber: TextFilter[];
  poNumber: TextFilter[];
  prNumber: TextFilter[];
  invoiceDateRange: DateRangeFilter;
  fiscalYear: TextFilter[];
  poCreator: TextFilter[];
  prCreator: TextFilter[];
  plants: TextFilter[];
  purchGrp: TextFilter[];
  nestleSupplierInvoice: TextFilter[];
  workflowAgent: TextFilter[];
  parkingReasonCode: TextFilter[];
  blckRsn: TextFilter[];
}

export interface IDocflowFilters {
  companies: TextFilter[];
  pOrgs: TextFilter[];
  pGroups: TextFilter[];
  plants: TextFilter[];
  vendorNo: TextFilter[];
  costCenter: TextFilter[];
  projectId: TextFilter[];
  requester: TextFilter[];
  poRequestor: TextFilter[];
  prRequestor: TextFilter[];
  complianceIndicator: TextFilter[];
  status: TextFilter[];
  deliveryDateRange: DateRangeFilter;
  creationDate: CreationDateFilter;
  priceRangeFrom: TextFilter[];
  priceRangeTo: TextFilter[];
  priceRange: TextFilter[];
  currency: TextFilter[];
  poNumber: TextFilter[];
  prNumber: TextFilter[];
  material: TextFilter[];
  docType: TextFilter[];
  extContactNumber: TextFilter[];
  ppmCostPlan: TextFilter[];
  sourcingID: TextFilter[];
  extReqNumber: TextFilter[];
  pslID: TextFilter[];
  spendCat: TextFilter[];
  agreementFlag: TextFilter[];
  agreementNflag: TextFilter[];
  materialType: TextFilter[];
}

export interface IPslFilters {
  spendCatL1s: TextFilter[];
  spendCatL2s: TextFilter[];
  spendCatL3s: TextFilter[];
  companies: TextFilter[];
  plants: TextFilter[];
  markets: TextFilter[];
  zones: TextFilter[];
  statuses: TextFilter[];
  suppliers: TextFilter[];
  materials: TextFilter[];
  businessUnits: TextFilter[];
  strategicBuyers: TextFilter[];
  supplierDiversity: TextFilter[];
  wayOfBuying: TextFilter[];
  scope: TextFilter[];
  pslCreationAndValidityDate: CreationDateFilter;
  showObsolete: boolean;
  supplierDescription: TextFilter[];
}

export interface IFairMarkit {
  materials: TextFilter[];
  plants: TextFilter[];
  materialType: TextFilter[];
}

export interface IPslMaterialFilters { }

export interface IMrpFilters {
  materials: TextFilter[];
  plants: TextFilter[];
  suppliers: TextFilter[];
  purchasingOrganizations: TextFilter[];
  catalogType: string;
  contractCreationDateRange: DateRangeFilter;
  createdBy: TextFilter[];
  updatedBy: TextFilter[];
  buyer: TextFilter[];
  supplierPartId: SimpleTextFilter;
  contractId: SimpleTextFilter;
  includeDeleted: boolean;
  excludeExistingContracts: boolean;
  includeExistingContracts: boolean;
  materialNumberBlankOnly: boolean;
  materialNumberOnly: boolean;
  getExpiredPrize: boolean;
  scope: string;
  status: number;
  expiringPrizeIn: number;
  contractSyncError: boolean;
}

export interface ICBFilters {
  companies: TextFilter[];
  competitiveBiddingCreators: any[];
  suppliers: TextFilter[];
  spendCatL1s: TextFilter[];
  spendCatL2s: TextFilter[];
  spendCatL3s: TextFilter[];
  validFrom: string;
  validTo: string;
  statuses: TextFilter[];
}

export interface IUserScopeFilters {
  businessUnits: TextFilter[];
  companies: TextFilter[];
  markets: TextFilter[];
  spendCatL1s: TextFilter[];
  spendCatL2s: TextFilter[];
  spendCatL3s: TextFilter[];
}

export interface IFrontendLists {
  PslVendors: any;
  PslMaterials: any;
  PslMyTasks: any;
  RcDocsTab: any;
  RcInvoicesTab: any;
  RCItemsColumns: any;
  RCItemsColumnsInv: any;
  RCGrsColumns: any;
  RCAccColumns: any;
  RCGrsColumnsInv: any;
  RCIrsColumns: any;
  RCIrsColumnsInv: any;
  NbsEasyRequestTab: any;
  NbsBlockedParkedTab: any;
  NbsSapScopeTab: any;
  NbsTrackerTab: any;
  PslCatalogColumns: any;
  NbsMaintenanceColumns: any;
  NbsSapScopeColumns: any,

}

export interface INbsEasyRequestFilters {
  agingDays: any,
  category: any,
  changedOn: any,
  collectiveNumber: any,
  companyCode: any,
  ftValidation: any,
  prCreatorName: any,
  purchOrg: any,
  purchaseOrdNo: any,
  purchaseReqNo: any,
  requisitioner: any,
  resend_po: any
}

export interface INbsBockedParkedFilters {
  aging: any,
  assignedTo: any,
  blockingReasonCode: any,
  compCode: any,
  dueDate: any,
  fiscalYear: any,
  invoiceNumber: any,
  pOrg: any,
  parkingReasonCode: any,
  priceRangeFrom: any,
  priceRangeTo: any,
  status: any,
  requestor: any,
  purchasingGroup: any,
  plant: any,
  spender: any,
  supplierInvoiceNumber: any,
  taskDate: any,
  instructionDate: any,
  taskResponsible: any,
  vendorNo: any,
}

export interface INbsSapScopeFilters {
  prNo: any,
  poNo: any,
  priceRangeTo: any,
  priceRangeFrom: any,
  thresholdRangeTo: any,
  thresholdRangeFrom: any,
  thresholdStatus: any,
  complianceIndicator: any,
  creator: any,
}

export interface INbsTrackerFilters {
  invoiceNumber: any,
  poNumber: any,
  requestorId: any,
  vendorNumber: any,
  supplierInvoiceNumber: any,
  purchashingGroup: any,
  parkingBlockingReason: any,
  taskResponsible: any,
  taskTaggedTo: any,
  fiscalYear: any,
  status: any,
  instructionDate: any
  companyCode: any,
  dueDate: any,
  aging: any,
  toActorOn: any,
  postingDate: any,
  dateOfProcessing: any

}

export interface INbsTemplateFilters {
  compCode: any,
  purOrg: any,
  purGroup: any,
  plant: any,
  material: any,
  parkingBlockingReason: any,
  receiver: any,
}

export interface UserFiltersDTO {
  contracts: IContractFilters;
  contractCalendar: IContractCalendarFilters;
  documentFlow: IDocflowFilters;
  favDocumentFlow: IDocflowFilters;
  invoices: IInvoiceFilters;
  psl: IPslFilters;
  mrp: IMrpFilters;
  competitiveBidding: ICBFilters;
  userScope: IUserScopeFilters;
  businessApproval: IBAFilters;
  purchasingApproval: IPAFilters;
  approvers: IAFilters;
  frontendLists: IFrontendLists;
  nbsCockpit: INbsEasyRequestFilters;
  blockedParked: INbsBockedParkedFilters;
  sapScope: INbsSapScopeFilters;
  nbsTracker: INbsTrackerFilters;
  nbsTemplate: INbsTemplateFilters;
  pslCatalog: IPslFilters,
  fairMarkit: IFairMarkit
}

export interface Change {
  id: number;
  change: string;
}

export interface Changelog {
  dateOfChanges: string;
  changes: Change[];
}

export interface UserFeedback {
  type: string;
  bugMessage: string;
  improvementMessage: string;
  newFeatureMessage: string;
  rating: number;
  netPromoterScore: number;
  module: string;
}

export interface IContractSpecificProfile {
  allowToResendContractChanges: boolean;
  buyers: { code: string; name: string }[];
  clusters: { code: string; name: string }[];
  zones: { code: string; name: string }[];
  markets: { id: string; name: string }[];
  spendCategoryCodes: { code: string; name: string }[];
  companyCodes: { code: string; name: string }[];
  plants: { code: string; name: string }[];
  businesses: { code: string; name: string }[];
}

interface UserDTO {
  id: number;
  name: string;
  photoUrl: null;
  email: string;
  userType: null;
  country: Country;
  isAdmin: boolean;
  mrp: string;
  lastLoginDateTimeStamp: number;
  lineManager: string;
  company: string;
  companyCode: string;
  market: string;
  jobTitle: string;
  jobPositionTitle: string;
  positionId: number;
  poChange: SAPBoolean;
  poCreate: SAPBoolean;
  prChange: SAPBoolean;
  prCreate: SAPBoolean;
  contractCreate: SAPBoolean;
  contractChange: SAPBoolean;
  filters: UserFiltersDTO;
  approvalType: string;
  userRole: string;
  baPa: string;
  defaultSystemAlias: string[];
  purchaseOrg: string;
  userPreferredCompanyCode: string;
  userPreferredCompanyName: string;
  userPreferredPslPersona: IUserRole;
  userSapPersona: IUserRole;
  userSapPersonaPslOnly: IUserRole;
  pslExpiryDays: number;
  selectedZoneId: number;
  selectedZoneName: string;
  unreadChangeLogs: Changelog[];
  onPremisesSamAccountName: string;
  operationalBuyer: string | null;
  isUserFeedbackRequired: boolean;
  npdBuyer: SAPBoolean;
  procSpec: SAPBoolean;
  profileSettings: {
    dateTimeFormat: string;
    timeZone: string;
    decimalNotation: string;
    contractSpecificProfile: IContractSpecificProfile;
  };
  isDocflowChatPilotUser: boolean; //TODO: temporal field, remove when possible.
  isApprover: boolean;
}

export const defaultCompany: (User) => TextFilter = (user: User) => {
  return {
    id: user.userPreferredCompanyCode,
    title: user.userPreferredCompanyName
  };
};

export const defaultStrategicBuyer: (User) => TextFilter = (user: User) => {
  return { id: user.accountName, title: user.name };
};

export interface IProfileSettings {
  dateTimeFormat: string;
  timeZone: string;
  decimalNotation: string;
  contractSpecificProfile: IContractSpecificProfile;
}

export interface IConfigurableSettings extends IProfileSettings {
  selectedZoneId: number;
  userPreferredCompanyCode: string;
  userPreferredCompanyName: string;
}

abstract class UserBase {
  constructor(
    public id: number,
    public name: string,
    public photoUrl: string,
    public companyCode: string,
    public filters: UserFiltersDTO,
    public contractChange: boolean,
    public contractCreate: boolean,
    public prChange: boolean,
    public prCreate: boolean,
    public poChange: boolean,
    public poCreate: boolean,
    public email: string,
    public userType: string,
    public country: Country,
    public isAdmin: boolean,
    public isMrpPlanner: boolean,
    public lastLoginDateTimeStamp: number,
    public lineManager: string,
    public company: string,
    public market: string,
    public jobTitle: string,
    public jobPositionTitle: string,
    public positionId: number,
    public approvalType: string,
    public userRole: string,
    public baPa: string,
    public defaultSystemAlias: string[] = [],
    public purchaseOrg: string = "",
    public userPreferredCompanyCode: string = "",
    public userPreferredCompanyName: string = "",
    public userPreferredPslPersona: IUserRole,
    public userSapPersona: IUserRole,
    public userSapPersonaPslOnly: IUserRole,
    public pslExpiryDays: number = 30,
    public selectedZoneId: number = null,
    public selectedZoneName: string = "",
    public changeIds: number[],
    public changes: Changelog[],
    public accountName: string,
    public isUserFeedbackRequired: boolean = false,
    public npdBuyer: boolean = false,
    public operationalBuyer: boolean = false,
    public procSpec: boolean = false,
    public profileSettings: IProfileSettings = {
      dateTimeFormat: null,
      timeZone: null,
      decimalNotation: null,
      contractSpecificProfile: {
        allowToResendContractChanges: false,
        buyers: [],
        clusters: [],
        zones: [],
        markets: [],
        spendCategoryCodes: [],
        companyCodes: [],
        plants: [],
        businesses: []
      }
    },
    public isDocflowChatPilotUser: boolean, //TODO: temporal field. remove when possible
    public isApprover: boolean
  ) { }

  abstract getUserRole(): IUserRole;
  abstract isViewOnly(): boolean;
}

export class User extends UserBase {

  static FromBackend(user: UserDTO): User {
    const changeIds =
      user && user.unreadChangeLogs && user.unreadChangeLogs.length > 0
        ? user.unreadChangeLogs.reduce(
          (previousValue: number[], currentValue: Changelog) => {
            return R.concat(
              previousValue,
              currentValue.changes.map((value: Change) => value.id)
            );
          },
          []
        )
        : [];
    return new User(
      user.id,
      user.name,
      user.photoUrl,
      user.companyCode,
      null,
      SAPBooleanToBoolean(SAPBoolean.TRUE),
      SAPBooleanToBoolean(SAPBoolean.TRUE),
      SAPBooleanToBoolean(SAPBoolean.TRUE),
      SAPBooleanToBoolean(SAPBoolean.TRUE),
      SAPBooleanToBoolean(SAPBoolean.TRUE),
      SAPBooleanToBoolean(SAPBoolean.TRUE),
      user.email,
      user.userType,
      user.country,
      user.isAdmin,
      SAPBooleanToBoolean(user.mrp as SAPBoolean),
      user.lastLoginDateTimeStamp,
      user.lineManager,
      user.company,
      user.market,
      user.jobTitle,
      user.jobPositionTitle,
      user.positionId,
      user.approvalType,
      user.userRole,
      user.baPa,
      R.defaultTo([], user.defaultSystemAlias).filter(s => !!s),
      user.purchaseOrg,
      user.userPreferredCompanyCode,
      user.userPreferredCompanyName,
      user.userPreferredPslPersona,
      user.userSapPersona,
      user.userSapPersonaPslOnly,
      user.pslExpiryDays,
      user.selectedZoneId,
      user.selectedZoneName,
      changeIds,
      user.unreadChangeLogs,
      user.onPremisesSamAccountName,
      user.isUserFeedbackRequired,
      SAPBooleanToBoolean(user.npdBuyer),
      user.operationalBuyer === "X",
      SAPBooleanToBoolean(user.procSpec),
      user.profileSettings,
      user.isDocflowChatPilotUser,
      user.isApprover
    );
  }

  getUserRole = (): IUserRole => {
    // return IUserRole.AT_MARKET; // Market persona
    // return IUserRole.ABOVE_MARKET; // Above Market persona
    // return IUserRole.FACTORY_ANALYST; // factory
    // return IUserRole.REQUESTOR; // Spender
    // return IUserRole.CATEGORY_LEAD; // PSL Approver
    // return IUserRole.PPA_AT_MARKET; // Procurement Performance Acceleration At Market
    // return IUserRole.PPA_ABOVE_MARKET; // Procurement Performance Acceleration Above Market
    return (
      this.userSapPersonaPslOnly ??
      this.userPreferredPslPersona ??
      IUserRole.REQUESTOR
    );
  };

  isRequestor = (): boolean => this.getUserRole() === IUserRole.REQUESTOR;
  isOperationalBuyer = (): boolean => this.getUserRole() === IUserRole.OPERATIONAL_BUYER;
  canApprove = (): boolean => this.isApprover === true;
  isViewOnly = (): boolean => this.getUserRole() === IUserRole.VIEW_ONLY;
  isPPAAboveMarketUser = (): boolean => this.getUserRole() === IUserRole.PPA_ABOVE_MARKET;
  isAboveMarketUser = (): boolean => this.getUserRole() === IUserRole.ABOVE_MARKET;
  isAtMarketUser = (): boolean => this.getUserRole() === IUserRole.AT_MARKET;
  isPPAAtMarketUser = (): boolean => this.getUserRole() === IUserRole.PPA_AT_MARKET;
  hasAboveMarketRole = (): boolean => [IUserRole.ABOVE_MARKET, IUserRole.PPA_ABOVE_MARKET].includes(this.getUserRole());
  hasAtMarketRole = (): boolean => [IUserRole.PPA_AT_MARKET, IUserRole.AT_MARKET].includes(this.getUserRole());
  isFactoryAnalyst = (): boolean => this.getUserRole() === IUserRole.FACTORY_ANALYST;
  isAtMarket = (): boolean => {
    const atMarketUsers = [
      IUserRole.AT_MARKET,
      IUserRole.ABOVE_MARKET,
      IUserRole.PPA_AT_MARKET,
      IUserRole.PPA_ABOVE_MARKET
    ];
    return atMarketUsers.includes(this.getUserRole());
  };
  hasMaintenance = (): boolean => [IUserRole.PPA_AT_MARKET, IUserRole.PPA_ABOVE_MARKET].includes(this.getUserRole());
}

export class MrpUser extends UserBase {

  constructor(...p: ConstructorParameters<typeof User>) {
    super(...p)
  }

  static fromUser(user: User): MrpUser {
    const args: any = Object.getOwnPropertyNames(user).map((v: string) => user[v])
    // @ts-ignore
    return new MrpUser(...args)
  }

  getUserRole = (): IUserRole => this.userSapPersona ?? this.userPreferredPslPersona ?? IUserRole.REQUESTOR;
  isViewOnly = (): boolean => [IUserRole.REQUESTOR, IUserRole.OPERATIONAL_BUYER].includes(this.getUserRole());
  isFactoryAnalyst = (): boolean => this.getUserRole() === IUserRole.FACTORY_ANALYST;
  isAtMarket = ():boolean => this.getUserRole() === IUserRole.AT_MARKET;
  isAboveMarket = ():boolean => this.getUserRole() === IUserRole.ABOVE_MARKET;

    cannotExecute = (): boolean => {
      const role = this.getUserRole();
      return (
        role === IUserRole.REQUESTOR ||
        role === IUserRole.FACTORY_ANALYST ||
        role === IUserRole.OPERATIONAL_BUYER
      );
    }

}

const toLocalRangeDate = (date: DateRangeFilter): DateRangeFilter => {
  if (date) {
    return {
      startDate: DateTime.fromISO(date.startDate).toFormat("yyyyMMdd"),
      endDate: DateTime.fromISO(date.endDate).toFormat("yyyyMMdd")
    };
  }
  return null;
};

const toLocalCreationValidityDate = (data: CreationDateFilter): CreationDateFilter => {
  // changed dateformat for below..... for PSL creationAndValidityDate
  return {
    buttonName: data.buttonName,
    startDate: DateTime.fromISO(data.startDate).toFormat("yyyyMMdd"),
    endDate: DateTime.fromISO(data.endDate).toFormat("yyyyMMdd"),
    type: data.type
  };
}

const formatCreationDate = (data: CreationDateFilter): CreationDateFilter => {
  const tempData = data?.buttonName ?
    {
      buttonName: data.buttonName,
      startDate: data.startDate,
      endDate: data.endDate,
      type: data.type
    } :
    {
      "type": "PO",
      "buttonName": null,
      "startDate": null,
      "endDate": null
    };
  return tempData;
};

export const filtersMapper = (
  filters: UserFiltersDTO,
  user: UserDTO = null
) => {
  const docFlowFilters = (filters) => ({
    companies: R.defaultTo([], filters.companies),
    pOrgs: R.defaultTo([], filters.pOrgs),
    pGroups: R.defaultTo([], filters.pGroups),
    plants: R.defaultTo([], filters.plants),
    prCreationDateRange: toLocalRangeDate(
      filters.prCreationDateRange
    ),
    creationDate: formatCreationDate(
      filters.creationDate
    ),
    poCreationDateRange: toLocalRangeDate(
      filters.poCreationDateRange
    ),
    deliveryDateRange: toLocalRangeDate(
      filters.deliveryDateRange
    ),
    vendors: R.defaultTo([], filters.vendorNo),
    costCenter: R.defaultTo([], filters.costCenter),
    projectId: R.defaultTo([], filters.projectId),
    requestor: R.defaultTo([], filters.requester),
    complianceIndicator: R.defaultTo(
      [],
      filters.complianceIndicator
    ),
    status: R.defaultTo([], filters.status),
    priceRangeFrom: R.defaultTo([], filters.priceRangeFrom),
    priceRangeTo: R.defaultTo([], filters.priceRangeTo),
    currency: R.defaultTo([], filters.currency),
    material: R.defaultTo([], filters.material),
    poNumber: R.defaultTo([], filters.poNumber),
    prNumber: R.defaultTo([], filters.prNumber),
    spendCat: R.defaultTo([], filters.spendCat),
    agreementFlag: R.defaultTo([], filters.agreementFlag),
    agreementNflag: R.defaultTo([], filters.agreementNflag),
    materialType: R.defaultTo([], filters.materialType),
  })
  const favDocFlowFiltersKey = 'favDocumentFlow';
  return {
    contracts: R.mergeRight(filters?.contracts, {
      companies: R.defaultTo([], filters?.contracts?.companies),
      pOrgs: R.defaultTo([], filters?.contracts?.pOrgs),
      pGroups: R.defaultTo([], filters?.contracts?.pGroups),
      docTypes: R.defaultTo([], filters?.contracts?.docTypes),
      plants: R.defaultTo([], filters?.contracts?.plants),
      mGroups: R.defaultTo([], filters?.contracts?.mGroups),
      strategicBuyers: R.defaultTo(
        user && SAPBooleanToBoolean(user.npdBuyer)
          ? [{ id: user.onPremisesSamAccountName, title: user.name }]
          : [],
        filters?.contracts?.strategicBuyers
      )
    }),
    purchasingApproval: R.mergeRight(filters?.purchasingApproval, {
      companies: R.defaultTo([], filters?.purchasingApproval?.companies),
      purchaseReqNo: R.defaultTo([], filters?.purchasingApproval?.purchaseReqNo),
      prCreatorName: R.defaultTo([], filters?.purchasingApproval?.prCreatorName),
      supplier: R.defaultTo([], filters?.purchasingApproval?.supplier),
      purchGroup: R.defaultTo([], filters?.purchasingApproval?.purchGroup),

    }),
    businessApproval: R.mergeRight(filters?.businessApproval, {
      companies: R.defaultTo([], filters?.businessApproval?.companies),
      purchaseReqNo: R.defaultTo([], filters?.businessApproval?.purchaseReqNo),
      prCreatorName: R.defaultTo([], filters?.businessApproval?.prCreatorName),
      supplier: R.defaultTo([], filters?.businessApproval?.supplier),
      purchGroup: R.defaultTo([], filters?.businessApproval?.purchGroup),
      wbsElement: R.defaultTo([], filters?.businessApproval?.wbsElement),
      costCenter: R.defaultTo([], filters?.businessApproval?.costCenter),
    }),
    contractCalendar: R.mergeRight(filters?.contractCalendar, {
      buyers: R.defaultTo([], filters?.contractCalendar?.buyers),
      negotiationType: (<any>(
        R.defaultTo([], filters?.contractCalendar?.negotiationType)
      )).map(v => ({
        id: parseInt(v.id as any),
        title: v.title
      }))
    }),
    invoices: R.mergeRight(filters?.invoices, {
      companies: R.defaultTo([], filters?.invoices?.companies),
      pOrgs: R.defaultTo([], filters?.invoices?.pOrgs),
      supplier: R.defaultTo([], filters?.invoices?.supplier),
      invoiceNumber: R.defaultTo([], filters?.invoices?.invoiceNumber),
      poNumber: R.defaultTo([], filters?.invoices?.poNumber),
      prNumber: R.defaultTo([], filters?.invoices?.prNumber),
      invoiceDateRange: toLocalRangeDate(filters?.invoices?.invoiceDateRange),
      fiscalYear: R.defaultTo([], filters?.invoices?.fiscalYear)
    }),
    documentFlow: R.mergeRight(filters?.documentFlow, docFlowFilters(filters?.documentFlow)),
    favDocumentFlow: R.mergeRight(filters?.favDocumentFlow, docFlowFilters(filters?.[favDocFlowFiltersKey])),
    psl: R.mergeRight(filters?.psl, {
      spendCatL1s: R.defaultTo([], filters?.psl?.spendCatL1s),
      spendCatL2s: R.defaultTo([], filters?.psl?.spendCatL2s),
      spendCatL3s: R.defaultTo([], filters?.psl?.spendCatL3s),
      companies: R.defaultTo([], filters?.psl?.companies),
      markets: R.defaultTo([], filters?.psl?.markets),
      zones: R.defaultTo([], filters?.psl?.zones),
      statuses: R.defaultTo([], filters?.psl?.statuses),
      pslCreationAndValidityDate: filters?.psl?.pslCreationAndValidityDate != null ? [toLocalCreationValidityDate(filters?.psl?.pslCreationAndValidityDate)] : null
    }),
    pslCatalog: R.mergeRight(filters?.pslCatalog, {
      spendCatL1s: R.defaultTo([], filters?.pslCatalog?.spendCatL1s),
      spendCatL2s: R.defaultTo([], filters?.pslCatalog?.spendCatL2s),
      spendCatL3s: R.defaultTo([], filters?.pslCatalog?.spendCatL3s),
      companies: R.defaultTo([], filters?.pslCatalog?.companies),
      markets: R.defaultTo([], filters?.pslCatalog?.markets),
      zones: R.defaultTo([], filters?.pslCatalog?.zones),
      statuses: R.defaultTo([], filters?.pslCatalog?.statuses),
      pslCreationAndValidityDate: filters?.pslCatalog?.pslCreationAndValidityDate != null ? [toLocalCreationValidityDate(filters?.pslCatalog?.pslCreationAndValidityDate)] : null
    }),
    fairMarkit: R.mergeRight(filters?.fairMarkit, {
      materials: R.defaultTo([], filters?.fairMarkit?.materials),
      plants: R.defaultTo([], filters?.fairMarkit?.plants),
      materialType: R.defaultTo([], filters?.fairMarkit?.materialType),
    }),
    mrp: R.mergeRight(filters?.mrp, {
      materials: R.defaultTo([], filters?.mrp?.materials),
      plants: R.defaultTo([], filters?.mrp?.plants),
      suppliers: R.defaultTo([], filters?.mrp?.suppliers),
      purchasingOrganizations: R.defaultTo(
        [],
        filters?.mrp?.purchasingOrganizations
      ),
      catalogType: R.defaultTo(null, filters?.mrp?.catalogType),
      contractCreationDateRange: toLocalRangeDate(
        filters?.mrp?.contractCreationDateRange
      ),
      createdBy: R.defaultTo([], filters?.mrp?.createdBy),
      updatedBy: R.defaultTo([], filters?.mrp?.updatedBy),
      excludeExistingContracts: R.defaultTo(
        false,
        filters?.mrp?.excludeExistingContracts
      ),
      materialNumberBlankOnly: R.defaultTo(
        false,
        filters?.mrp?.materialNumberBlankOnly
      ),
      includeDeleted: R.defaultTo(false, filters?.mrp?.includeDeleted)
    }),
    competitiveBidding: R.mergeRight(filters?.competitiveBidding, {
      companies: R.defaultTo([], filters?.competitiveBidding?.companies),
      competitiveBiddingCreators: (<any>(
        R.defaultTo([], filters?.competitiveBidding?.competitiveBiddingCreators)
      )).map(x => ({
        id: x,
        title: x
      })),
      suppliers: R.defaultTo([], filters?.competitiveBidding?.suppliers),
      spendCatL1s: R.defaultTo([], filters?.competitiveBidding?.spendCatL1s),
      spendCatL2s: R.defaultTo([], filters?.competitiveBidding?.spendCatL2s),
      spendCatL3s: R.defaultTo([], filters?.competitiveBidding?.spendCatL3s),
      statuses: R.defaultTo([], filters?.competitiveBidding?.statuses)
    }),
    userScope: R.mergeRight(filters?.userScope, {
      businessUnits: R.defaultTo([], filters?.userScope?.businessUnits),
      companies: R.defaultTo([], filters?.userScope?.companies),
      markets: R.defaultTo([], filters?.userScope?.markets),
      spendCatL1s: R.defaultTo([], filters?.userScope?.spendCatL1s),
      spendCatL2s: R.defaultTo([], filters?.userScope?.spendCatL2s),
      spendCatL3s: R.defaultTo([], filters?.userScope?.spendCatL3s)
    }),
    approvers: R.mergeRight(filters?.approvers, {
      markets: R.defaultTo(
        [],
        filters?.approvers?.markets?.map((e: any) => ({
          id: e.id,
          title: e.name
        }))
      ),
      companies: R.defaultTo(
        [],
        filters?.approvers?.companies?.map((e: any) => ({
          id: e.id,
          title: `${e.code} - ${e.name}`
        }))
      ),
      spendCatL1s: R.defaultTo([], filters?.approvers?.spendCatL1s),
      spendCatL2s: R.defaultTo([], filters?.approvers?.spendCatL2s),
      spendCatL3s: R.defaultTo([], filters?.approvers?.spendCatL3s),
      pslCreationAndValidityDate: filters?.approvers?.pslCreationAndValidityDate != null ? [toLocalCreationValidityDate(filters?.approvers?.pslCreationAndValidityDate)] : null
    }),
    frontendLists: R.mergeRight(filters?.frontendLists, {
      PslVendors: R.defaultTo([], filters?.frontendLists?.PslVendors),
      PslMaterials: R.defaultTo([], filters?.frontendLists?.PslMaterials),
      PslMyTasks: R.defaultTo([], filters?.frontendLists?.PslMyTasks),
      RcDocsTab: R.defaultTo([], filters?.frontendLists?.RcDocsTab),
      RcInvoicesTab: R.defaultTo([], filters?.frontendLists?.RcInvoicesTab),
      RCItemsColumns: R.defaultTo([], filters?.frontendLists?.RCItemsColumns),
      RCItemsColumnsInv: R.defaultTo([], filters?.frontendLists?.RCItemsColumnsInv),
      RCGrsColumns: R.defaultTo([], filters?.frontendLists?.RCGrsColumns),
      RCAccColumns: R.defaultTo([], filters?.frontendLists?.RCAccColumns),
      RCGrsColumnsInv: R.defaultTo([], filters?.frontendLists?.RCGrsColumnsInv),
      RCIrsColumns: R.defaultTo([], filters?.frontendLists?.RCIrsColumns),
      RCIrsColumnsInv: R.defaultTo([], filters?.frontendLists?.RCIrsColumnsInv),
      NbsEasyRequestTab: R.defaultTo([], filters?.frontendLists?.NbsEasyRequestTab),
      NbsSapScopeColumns: R.defaultTo([], filters?.frontendLists?.NbsSapScopeColumns),
      NbsBlockedParkedTab: R.defaultTo([], filters?.frontendLists?.NbsBlockedParkedTab),
      NbsTrackerTab: R.defaultTo([], filters?.frontendLists?.NbsTrackerTab),
      PslCatalogColumns: R.defaultTo([], filters?.frontendLists?.PslCatalogColumns),
      NbsMaintenanceColumns: R.defaultTo([], filters?.frontendLists?.NbsMaintenanceColumns),
    }),
    nbsCockpit: {
      agingDays: filters?.nbsCockpit?.agingDays,
      category: filters?.nbsCockpit?.category,
      changedOn: filters?.nbsCockpit?.changedOn,
      collectiveNumber: filters?.nbsCockpit?.collectiveNumber,
      companyCode: filters?.nbsCockpit?.companyCode,
      ftValidation: filters?.nbsCockpit?.ftValidation,
      prCreatorName: filters?.nbsCockpit?.prCreatorName,
      purchOrg: filters?.nbsCockpit?.purchOrg,
      purchaseOrdNo: filters?.nbsCockpit?.purchaseOrdNo,
      purchaseReqNo: filters?.nbsCockpit?.purchaseReqNo,
      requisitioner: filters?.nbsCockpit?.requisitioner,
      resend_po: filters?.nbsCockpit?.resend_po
    },
    blockedParked: {
      aging: filters?.blockedParked?.aging,
      assignedTo: filters?.blockedParked?.assignedTo,
      blockingReasonCode: filters?.blockedParked?.blockingReasonCode,
      compCode: filters?.blockedParked?.compCode,
      dueDate: filters?.blockedParked?.dueDate,
      fiscalYear: filters?.blockedParked?.fiscalYear,
      invoiceNumber: filters?.blockedParked?.invoiceNumber,
      pOrg: filters?.blockedParked?.pOrg,
      parkingReasonCode: filters?.blockedParked?.parkingReasonCode,
      priceRangeFrom: filters?.blockedParked?.priceRangeFrom,
      priceRangeTo: filters?.blockedParked?.priceRangeTo,
      status: filters?.blockedParked?.status,
      requestor: filters?.blockedParked?.requestor,
      purchasingGroup: filters?.blockedParked?.purchasingGroup,
      plant: filters?.blockedParked?.plant,
      spender: filters?.blockedParked?.spender,
      supplierInvoiceNumber: filters?.blockedParked?.supplierInvoiceNumber,
      taskDate: filters?.blockedParked?.taskDate,
      instructionDate: filters?.blockedParked?.instructionDate,
      taskResponsible: filters?.blockedParked?.taskResponsible,
      vendorNo: filters?.blockedParked?.vendorNo,
    },
    nbsTracker: {
      invoiceNumber: filters?.nbsTracker?.invoiceNumber,
      companyCode: filters?.nbsTracker?.companyCode,
      poNumber: filters?.nbsTracker?.poNumber,
      requestorID: filters?.nbsTracker?.requestorId,
      dueDate: filters?.nbsTracker?.dueDate,
      vendorNumber: filters?.nbsTracker?.vendorNumber,
      supplierInvoiceNumber: filters?.nbsTracker?.supplierInvoiceNumber,
      postingDate: filters?.nbsTracker?.postingDate,
      parkingBlockingReason: filters?.nbsTracker?.parkingBlockingReason,
      dateOfProcessing: filters?.nbsTracker?.dateOfProcessing,
      purchashingGroup: filters?.nbsTracker?.purchashingGroup,
      taskResponsible: filters?.nbsTracker?.taskResponsible,
      taskTaggedTo: filters?.nbsTracker?.taskTaggedTo,
      fiscalYear: filters?.nbsTracker?.fiscalYear,
      status: filters?.nbsTracker?.status,
      aging: filters?.nbsTracker?.aging,
      toActorOn: filters?.nbsTracker?.toActorOn,
      instructionDate: filters?.nbsTracker?.instructionDate,
    },
    sapScope: {
      prNo: filters.sapScope?.prNo,
      poNo: filters.sapScope?.poNo,
      priceRangeTo: filters.sapScope?.priceRangeTo,
      priceRangeFrom: filters.sapScope?.priceRangeFrom,
      thresholdRangeTo: filters.sapScope?.thresholdRangeTo,
      thresholdRangeFrom: filters.sapScope?.thresholdRangeFrom,
      thresholdStatus: filters.sapScope?.thresholdStatus,
      complianceIndicator: filters.sapScope?.complianceIndicator,
      creator: filters.sapScope?.complianceIndicator,
    },
    nbsTemplate: {
      compCode: filters?.nbsTemplate?.compCode,
      purOrg: filters?.nbsTemplate?.purOrg,
      purGroup: filters?.nbsTemplate?.purGroup,
      plant: filters?.nbsTemplate?.plant,
      material: filters?.nbsTemplate?.material,
      parkingBlockingReason: filters?.nbsTemplate?.parkingBlockingReason,
      receiver: filters?.nbsTemplate?.receiver
    }
  };
};
