import React from "react";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";
import { RootStateStore } from "../../application.reducers";
import { Landing, LandingContent, LandingHead, LandingContainer } from "../landing.styles";
import TileComponent from "../components/tile.component";
import { environment } from "src/app/shared/domains/core/environment";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { Features } from "src/app/shared/domains/core/pataflag";
import { EasyRequestIcon, NbsCockpitIcon, RequestCockpitIcon } from "./icons";

export const ShowNbsTile = ({ children }): JSX.Element => {
  const { hasFeature } = React.useContext(FeaturesContext);
  const user = useSelector((state: RootStateStore) => state.shared.user.currentUser);

  if (hasFeature(Features.SHOW_NBS_FOR_OP_BUYER_ONLY)) {
    if (!user.operationalBuyer) {
      return null;
    }
  }

  return children;
}

export default function LandingPage() {
  const user = useSelector((state: RootStateStore) => state.shared.user.currentUser);
  const { hasFeature } = React.useContext(FeaturesContext);
  const isRequestor = user.isRequestor()

  return (
    <Landing>
      <Helmet>
        <title>S2P Dashboard</title>
      </Helmet>
      <LandingHead>
        {
          user?.photoUrl && <img src={user.photoUrl} alt="user avatar" />
        }
        <span className="welcome">Welcome to S2P Browser, <span className="name">{user.name}</span></span>
        <span>Please select the application that you would like to use:</span>
      </LandingHead>
      <LandingContainer>
        <LandingContent>
          <TileComponent icon={'person'}
            title={'Preferred Supplier List'}
            desc={'This application gives you access to the list of suppliers selected by Procurement for Nestlé purchases - also called Preferred Supplier List (PSL). This is the very first place to look when there is something to buy in Nestlé.'}
            url={isRequestor ? '/pslCatalogs': '/psl'} />
          <TileComponent
            icon={
              <EasyRequestIcon stroke={"#f19002"} fill={"none"} width="48.73" height="31.403" />
            }
            title={'Easy Request'}
            desc={'Need to order something? Create your Purchase Requisitions in a few clicks only!(Note: you should have checked the Preferred Supplier List first)'}
            url={`${environment.EASY_REQUEST_URL}`}
            extLink={true}
          />
          <TileComponent
            icon={
              <RequestCockpitIcon stroke={"#f19002"} fill={"none"} width="48.753" height="45.245" />
            }
            title={'Request Cockpit'}
            desc={'One-stop portal to ease the follow-up on your Purchase Requisitions, Purchase Orders, Goods Receipts and Invoices'}
            url={'/document-flow'}
          />
          <TileComponent icon={'assignment_turned_in'}
            title={'Business Approval'}
            desc={'The business budget line item approval of Ariba EasyBuy purchase requisitions – considering the PR line item values.'}
            url={'/business-approval'} />
          <TileComponent icon={'assignment_turned_in'}
            title={'Procurement Approval'}
            desc={'The procurement header approval of Ariba EasyBuy purchase requisitions – considering the PR header total values.'}
            url={'/purchasing-approval'} />
          <TileComponent
            icon={'done_all'}
            title={'Configuration Cockpit'}
            desc={'Unlock efficiency with the Configuration Cockpit! Make instant amendments replicated to SAP and refresh workflows for i) PR Approver, ii) PO Approver and iii) Blocked & Parked Workflow. No more waiting on offshore tickets with long SLAs, experience streamlined and compliance workflow management today!'}
            url={`${environment.AMC_URL}`}
            extLink={true}
          />
          <TileComponent icon={'monetization_on'}
            title={'Competitive Bidding'}
            desc={'Competitive biddings are negotiations done with a minimum of 3 different suppliers to finally select one to work with. They ensure the best use of Nestlé’s expenditures while controlling compliance to the Nestlé Procurement policy.'}
            url={'/competitive-bidding'} />
          <TileComponent icon={'settings'}
            title={'MRP Pricing Manager'}
            desc={'Contains the Price List of the Service & Indirect Materials from the Suppliers of Nestle, creating Contract (Source List for MRP run) in SAP ERP systems.'}
            url={'/mrp'} />
          <TileComponent icon={'assignment'}
            title={'Contracts'}
            desc={'Management of Local and Above the market contracts.'}
            url={'/contracts'} />
          <TileComponent icon={'calendar_today'}
            title={'Tender calendar'}
            desc={'Planning of MVRG process. Calendar helps to define for markets dates by when requirements for direct materials should be provided to Above the Market team.'}
            url={'/contract/appointments'} />
          {
            <ShowNbsTile>
              {
                hasFeature(Features.NBS_COCKPIT) ? <TileComponent
                  icon={
                    <NbsCockpitIcon fill={"#F19101"} width="40px" height="40px" />
                  }
                  title={'NBS Cockpit'}
                  desc={'One-stop portal for Operational Buyers to ease the management of creation & change requests of Purchase Requisitions and Purchase Orders'}
                  url={'/nbs-cockpit/ob-validation'} /> : null
              }
            </ShowNbsTile>
          }
            <TileComponent
                icon={'shopping_cart'}
                title={'S&IM - Promotional Items'}
                desc={'Promotional Catalog Items'}
                url={`${environment.PROMO_CATALOG_URL}`}
                extLink={true}
            />
        </LandingContent>
      </LandingContainer>
    </Landing>
  )
}
